import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';

function Progress() {
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [userName, setUserName] = useState('');
  const title = "Progress";
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.user);
  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
}
  const fetchDiscussionstoday = useCallback(async () => {
    try {
      const response = await API.get('/admin/discussions/today');
      setData(response.data.groupedProgressContent);
      console.log("Today's data", response.data.groupedProgressContent);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);
  
  useEffect(() => {
    fetchDiscussionstoday();
  }, [fetchDiscussionstoday]);

  useEffect(() => {
    const fetchDiscussionstoday = async () => {
      try {
        const response = await API.get('/admin/discussions/today');
        setData(response.data.groupedProgressContent);
        console.log("Today's data", response.data.groupedProgressContent);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchDiscussionstoday();
  }, []);
  

  useEffect(() => {
    dispatch(pageTitle(title));
  }, [dispatch, title,fetchDiscussionstoday]);

  useEffect(() => {
    fetchDiscussions(fromDate, toDate, userName);
  }, [fromDate, toDate, userName]);

  const handleFromDateChange = async (date) => {
    setFromDate(date);
  };

  const handleToDateChange = async (date) => {
    setToDate(date);
  };

  const fetchDiscussions = async (from, to, user) => {
    if (from && to) {
      try {
        const response = await API.get('/admin/discussions', {
          params: {
            from: from.toISOString(),
            to: to.toISOString(),
            firstName: user 
          },
        });
        setData(response.data.groupedDiscussions);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const formatDateDisplay = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  const isToday = (date) => date.toDateString() === new Date().toDateString();
  const isYesterday = (date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date.toDateString() === yesterday.toDateString();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      fetchDiscussions(fromDate, toDate, userName);
    }
  };

  return (
    <div className="main-dashboard-admin">
       <div class="breadcrumbs">
                <Link to={"/"}>Home </Link><span><i class="fa fa-chevron-right"></i> 
                <Link className='sr-active-bread' to={"/progress"} > Progress</Link></span>
            </div>
      <div className='checklist-header-wrap'>
        <div className='progress-radio-out date-progress-section'>
        <div className="searchBar">
              <div className="field">
                <input
                  type="search"
                  placeholder="Search for User"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <button type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div> 
          <div className="select-date-pro">
            <div className='select-heading'><h4>Select Period:</h4></div>
            <div className="dateBlock">
              <div className="datePickerWrapper">
                <label htmlFor="fromDate">From Date</label>
                <DatePicker
                  id="fromDate"
                  selected={fromDate}
                  onChange={handleFromDateChange}
                  selectsStart
                  startDate={fromDate}
                  endDate={toDate}
                  maxDate={new Date()}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Select From Date"
                  className="datePickerInput"
                  customInput={
                    <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                      {(inputProps) => <input {...inputProps} />}
                    </InputMask>
                  }
                />
              </div>
              <div className="datePickerWrapper">
                <label htmlFor="toDate">To Date</label>
                <DatePicker
                  id="toDate"
                  selected={toDate}
                  onChange={handleToDateChange}
                  selectsEnd
                  startDate={fromDate}
                  endDate={toDate}
                  maxDate={new Date()}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Select To Date"
                  className="datePickerInput"
                  customInput={
                    <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                      {(inputProps) => <input {...inputProps} />}
                    </InputMask>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='main-progress-outer'>
        <div className='progress-inner'>
          {Object.keys(data).length > 0 ? (
            Object.entries(data).map(([date, projects]) => (
              <div key={date}>
                <div className='progress-top'>
                  <h2 className="date-heading">
                    {isToday(new Date(date)) ? 'Today' : isYesterday(new Date(date)) ? 'Yesterday' : formatDateDisplay(date)}
                  </h2>
                </div>
                {Object.values(projects).map((group, index) => (
                  <div key={`${date}_${index}`} className='progress-det-box'>
                    <div className='progress-grid-outer'>
                      <h4><Link to={group[0]?.linkPath}>{group[0]?.project?.opportunityName}</Link></h4>
                      <div className='progress-grid'>
                        {group.map((item, idx) => (
                          <div key={idx} className='progress-client-details'>
                            <div className='progress-client'>
                              <img src={item.user.profilePictureUrl} alt='Progress' />
                            </div>
                            <div className='progress-date'>
                              <p><Link to={item.linkPath} >{item.progress_title}</Link> by {userData?.user.firstName === item.user.firstName ? 'you' : `${item.user.firstName} ${item.user.lastName}`}</p>
                              <span>{new Date(item.datetime).toLocaleString()}</span>
                              <div className='text-pro'>
                                <p>{stripHtmlTags(item?.progress_content || '')}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <div className="no-data-found">
              <span> No data available</span>
            </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default Progress;
