import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../features/actions/pageTitleActions';
import { Link, NavLink } from 'react-router-dom';
import API from '../../AxiosConfig';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';


function AddTeam() {
  const [formData, setFormData] = useState({
    id: '',
    photo: '',
    firstName: '',
    lastName: '',
    email: '',
    password:'',
    confirmpassword:'',
    mobileNumber: '',
    roleId: ''
  });
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null); 

  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [files, setFile] = useState({});
  const [loading, setLoading] = useState(false);

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile(file);
        setImagePreview(reader.result); 
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    //console.log(e.target);
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let newErrors = {};
        if (formData.firstName.trim() === '') {
          newErrors = { ...newErrors, firstName: 'First name is required' };
        }
        if (formData.lastName.trim() === '') {
          newErrors = { ...newErrors, lastName: 'Last name is required' };
        }
        if (formData.email.trim() === '') {
          newErrors = { ...newErrors, email: 'Email is required' };
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          newErrors = { ...newErrors, email: 'Invalid email format' };
        }

        if (formData.password.trim() === '' ) {
          newErrors = { ...newErrors, password: 'Password is required' };
        } else if (/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(formData.password)) {
          newErrors = { ...newErrors, password: 'Minimum eight characters, at least one letter, one number and one special character' };
        }

        if (formData.confirmpassword.trim() === '' ) {
          newErrors = { ...newErrors, confirmpassword: 'Confirm password is required' };
        } else if (formData.confirmpassword !== formData.password) {
          newErrors = { ...newErrors, confirmpassword: 'Confirm password needs to be equal to the password' };
        }
        

        if (formData.roleId === '') {
          newErrors = { ...newErrors, roleId: 'User role is required' };
        }
        if (!files.name) {
            newErrors = { ...newErrors, photo: 'Photo is required' };
        } else {
          const validExtensions = ['jpg', 'jpeg', 'png'];
          const extension = files.name.split('.').pop().toLowerCase();
          if (!validExtensions.includes(extension)) {
            newErrors = { ...newErrors, photo: 'Only JPG and PNG files are allowed' };
          }
        }

        if (Object.keys(newErrors).length === 0) {

          const token = localStorage.getItem('token'); // Get token from local storage
          const data = new FormData();
          Object.keys(formData).map((fields) => {
            data.append(fields, formData[fields]);
          });
          data.append('media',files);
          // Call your API here
          // console.log(formData);
          setLoading(true);
              API.post('/admin/addTeam',data,{
                headers: {
                    Authorization: `Bearer ${token}`
                }
              })    
              .then((response)=>{
                if(response.status == 200){
                  toast.success("Member added successfully");
                  setLoading(false);
                  setTimeout(() => navigate('/team'), 2000);
                  setFormData({});
                }else if(response.status == 203){
                  setLoading(false);
                  toast.error("Email Already Exists");
                  setTimeout(() => navigate('/team'), 2000);
                  setFormData({});
                }
              }).catch((error) => {
                setLoading(false);
                console.log(error);
                toast.error("Member not added");
                setFormData({});
            });
       
          // console.log(formData);
        } else {
        setLoading(false);
          setErrors(newErrors);
        }

  }

  const getMemberDetail = () => {
    API.get('admin/memberDetail/' + id + '')
      .then((response) => {
        if (response.status === 200) {
          const member = response.data.data;
          setFormData({
            id: member.id,
            firstName: member.firstName,
            lastName: member.lastName,
            email: member.email,
            mobileNumber: member.mobileNumber,
            roleId: member.roleId,
            // ... other form fields
          });
        } else {
          console.log('error');
          // toast.error('Error while getting the data', {
          //     position: toast.POSITION.TOP_CENTER
          // });
        }
      })
      .catch((error) => {
        console.error(error)
      });
  }

  const dispatch = useDispatch();
  const title = id ? "Update Team Member" : "Add Team Member";
  useEffect(() => {
    dispatch(pageTitle(title));
    if (id) {
      getMemberDetail();
    }
  }, [])
  return (
    <div className="main-dashboard-admin sr-cstm-padding">
                <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
       {loading &&
            <div className='rotate-line-loader'>
                 <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
            </div>}

      <div className="form-add-member">
       
        <form onSubmit={handleSubmit}>
        <div className="file file--upload"><svg xmlns="http://www.w3.org/2000/svg" width="68.854" height="56.881" viewBox="0 0 68.854 56.881">
          <g id="Icon_feather-user-plus" data-name="Icon feather-user-plus" transform="translate(0 -3)">
            <path id="Path_7" data-name="Path 7" d="M46.4,40.46V34.474A11.974,11.974,0,0,0,34.427,22.5H13.474A11.974,11.974,0,0,0,1.5,34.474V40.46" transform="translate(0 17.921)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            <path id="Path_8" data-name="Path 8" d="M30.7,16.474A11.974,11.974,0,1,1,18.724,4.5,11.974,11.974,0,0,1,30.7,16.474Z" transform="translate(5.227)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            <path id="Path_9" data-name="Path 9" d="M30,12V29.96" transform="translate(28.374 7.467)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            <path id="Path_10" data-name="Path 10" d="M43.46,16.5H25.5" transform="translate(23.894 11.947)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
          </g>
        </svg>
          <input type="file" accept="image/*" className="form-control" id="input-file" name="photo" onChange={handlePhotoChange} />
          </div>
          {imagePreview && (
            <div className="image-preview">
              <img src={imagePreview} alt="Preview" style={{ maxWidth: '250px', height: 'auto' }} />
            </div>
          )}
          {errors.photo && <p style={{ color: 'red' }}>{errors?.photo}</p>}
          

          <div className="fields-row">
            <div className="single-field-form">
                <label>First Name:</label>
                <input type="text" name="firstName" maxLength={15} value={formData?.firstName} onChange={handleChange} />
                {errors.firstName && <p style={{ color: 'red' }}>{errors?.firstName}</p>}
            </div>
            <div className="single-field-form">
                  <label>Last Name:</label>
                 <input type="text" name="lastName" maxLength={15} value={formData?.lastName} onChange={handleChange} />
                 {errors.lastName && <p style={{ color: 'red' }}>{errors?.lastName}</p>}
            </div>
            <div className="single-field-form">
            <label>Email Address:</label>
               <input type="email" name="email" value={formData?.email} onChange={handleChange} />
               {errors.email && <p style={{ color: 'red' }}>{errors?.email}</p>}
            </div>
            <div className="single-field-form">
            <label>Password:</label>
               <input type="password" name="password" value={formData?.password} onChange={handleChange} />
               {errors.password && <p style={{ color: 'red' }}>{errors?.password}</p>}
            </div>
            <div className="single-field-form">
            <label>Confirm Password:</label>
               <input type="password" name="confirmpassword" value={formData?.confirmpassword} onChange={handleChange} />
               {errors.confirmpassword && <p style={{ color: 'red' }}>{errors?.confirmpassword}</p>}
            </div>
            <div className="single-field-form">
                <label>User Role:</label>
                <select name="roleId" onChange={handleChange}>
                    <option value="">Please Select</option>
                    <option value="1" selected={formData?.roleId=='1'}>Admin</option>
                    <option value="2" selected={formData?.roleId=='2'}>Manager</option>
                </select>
                {errors.roleId && <p style={{ color: 'red' }}>{errors?.roleId}</p>}
            </div>
          </div>

          <div className="form-btn-rows">
          <button className="global-icon-btn orange-btn" type="submit"><svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg> Save</button>
            <Link to={'/team'} className="global-icon-btn">
              <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
              Cancel
            </Link>
          </div>
        </form>
      </div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark"/>

    </div>
  )
}

export default AddTeam
