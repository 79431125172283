import React, { useState, useEffect } from 'react';
import KanbanColumn from './column';
import CreateColumn from './Model/createColumn';
import API from '../../AxiosConfig';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const KanbanBoard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [tasks, setTasks] = useState({});
  const [draggedOverCol, setDraggedOverCol] = useState(0);
  const [showBoardColumn, setShowBoardColumn] = useState(false);
  const [ArchiveTasks, setArchiveTasks] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleShowModalColumn = () => {
    setShowBoardColumn(true);
  };

  const fetchColumnsAndTasks = async () => {
    try {
      const columnResponse = await API.get(`/board/boardColumns/${id}`);
      const columnsData = columnResponse.data;
      setColumns(columnsData);

      const tasksMap = {};
      await Promise.all(
        columnsData.map(async (column) => {
          try {
            const response = await API.get(`/board/columnsTasks/${column.id}`);
            tasksMap[column.id] = response.data;
          } catch (error) {
            console.error(`Error fetching tasks for column ${column.id}:`, error);
            tasksMap[column.id] = [];
          }
        })
      );

      setTasks(tasksMap);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching columns:', error);
      setIsLoading(false);
    }
  };

  const fetchArchievedtasks = async () => {
    try {
      const response = await API.get(`/board/columnsArchiveTasks/${id}`);
      setArchiveTasks(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching columns:', error);
      setIsLoading(false);
    }
  };


  const refreshState = () => {
    fetchColumnsAndTasks();
  };

  const handleOnDragEnter = (e, stageValue) => {
    setDraggedOverCol(stageValue);
  };

  useEffect(() => {
    fetchColumnsAndTasks();
    fetchArchievedtasks();
  }, [id]);

  const handleOnDragEnd = async (e, task) => {
    try {
      const { id: taskId, columnId: newColumnId } = task;
      await API.patch(`/board/updateTaskColumn/${taskId}`, { columnId: newColumnId });
      const columnResponse = await API.get(`/board/boardColumns/${id}`);
      const columnsData = columnResponse.data;
      const tasksMap = {};
      await Promise.all(
        columnsData.map(async (column) => {
          try {
            const response = await API.get(`/board/columnsTasks/${column.id}`);
            tasksMap[column.id] = response.data;
          } catch (error) {
            console.error(`Error fetching tasks for column ${column.id}:`, error);
            tasksMap[column.id] = [];
          }
        })
      );

      setTasks(tasksMap);
    } catch (error) {
      console.error('Error updating task column:', error);
    }
  };

  if (isLoading) {
    return <h3>Loading...</h3>;
  }

  return (
    <div className="main-dashboard-admin">
      <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
      <div className='add-col-btn'>
       <Link to={`/archivedTask/${id}`} ><button>Archived Task</button></Link>
        <button onClick={handleShowModalColumn}>Add New Column</button>
        <CreateColumn showBoardColumn={showBoardColumn} setShowBoardColumn={setShowBoardColumn} boardId={id} onClose={refreshState} />
      </div>
      <div className='sr-main-outer-wrap'>
    {columns.length > 0 ? (
      columns.map((column) => (
        <KanbanColumn
          key={column.id}
          name={column.columnName}
          stage={column.stage}
          tasks={tasks[column.id] || []}
          onDragEnter={handleOnDragEnter}
          onDragEnd={handleOnDragEnd}
          columnId={column.id}
          boardId={id}
          onTaskCreated={refreshState}
        />
      ))

    ) : (
    <div className="empty-board">
      <p>The board is empty. Start adding columns to organize your tasks.</p>
    </div>
  )}
</div>

    </div>
  );
};

export default KanbanBoard;
