import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import FileUpload from '../FileUpload/FileUpload';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MultiSelect } from "react-multi-select-component";
import Card from 'react-bootstrap/Card';
import { ToastContainer, toast } from 'react-toastify';
import { Bars } from 'react-loader-spinner';
import { useSelector } from 'react-redux/es/hooks/useSelector';


function AddDiscussion() {
    const dispatch = useDispatch();
    const title = 'Add Discussion';
    const { id } = useParams();
    const { discussion_id } = useParams();
    const keys = { title: '', description: '', projectId: id, userId: [] };
    const [filesData, setFilesData] = useState({ files: '' })
    const [formData, setFormData] = useState(keys);
    const [teamMembers, setTeamMembers] = useState({});
    const [awaitingTeamMembers, setawaitingTeamMembers] = useState(null);
    const [selected, setSelected] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [lastDiscussion, setLastDiscussion] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [showAddTeamMember, setShowAddTeamMember] = useState(null);
    const [loading, setLoading] = useState(false);
    let options = [];
    const userData = useSelector(state => state.auth.user);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const handleChangeFiles = (event) => {
        const files = Array.from(event.target.files); // Convert FileList to an array
        setSelectedFiles(files);
    };

    if (awaitingTeamMembers && awaitingTeamMembers.length > 0) {
        awaitingTeamMembers.map((team) => {
            if (team.id != id) {
                options.push({ label: team['firstName'] + ' ' + team['lastName'], value: team['id'], projectId: id })
            }
        });
    }

    const getTeamAndWaitingMembers = () => {
        API.get(`/admin/get-assoicated-awaiting-team-for-project/${id}`)
            .then((response) => {
                console.log(response.data)
                setTeamMembers(response.data.team);
                setLastDiscussion(response.data.latestDiscussion)
                setawaitingTeamMembers(response.data.awaitingTeam);
            }).catch((e) => {
                console.log(e);
            })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name !== 'files') {
            setFormData({ ...formData, [name]: value });
        }
        else if (name === 'files') {
            const file = e.target.files;
            if (file) {
                setFilesData({ ...filesData, [name]: file });
            }
        }
    }

    const editDiscussion = () => {
        const token = localStorage.getItem('token');
        API.get(`/admin/findDiscussion/${discussion_id}`,
            { headers: { Authorization: `Bearer ${token}` } }
        ).then((response) => {
            const data = response.data.data;
            formData.id = data.id;
            formData.title = data.title;
            formData.description = data.description;
            setFormData(formData);
        }).catch((e) => {
            console.log(e);
        });
    }

    const handleSubmit = () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const data = formData;
        console.log(formData);
        data.userData = userData.user;
        data.files = filesData.files;
        const error = handleErrors(data);
        if (!error) {
            let form = new FormData();
            for (let i = 0; i < filesData.files.length; i++) {
                form.append('files', filesData.files[i]);
            }
            Object.keys(formData).forEach(key => {
                form.append(key, formData[key])
            });

            for (let i = 0; i < userIds.length; i++) {
                form.append('userId', userIds[i]);
            }
            if (!discussion_id) {
                console.log("Form Submission",form);
                API.post('/admin/add-disscussion-to-project', form, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                })
                    .then((response) => {
                        setFormData(keys);
                        setLoading(false);
                        toast.success("Discussion added successfully");
                        setTimeout(() => navigate(`/clients/client-detail/project-detail/discussions/${id}`), 1000);
                    }).catch((e) => {
                        console.log(e);
                        setLoading(false);
                        toast.error("Something went wrong");
                    });
            }
            else {
                API.post('/admin/update-disscussion-of-project', form, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }).then((response) => {
                    setFormData(keys);
                    setLoading(false);
                    toast.success("Discussion updated successfully");
                    setTimeout(() => navigate(`/clients/client-detail/project-detail/discussions/${id}`), 1000);
                }).catch((e) => {
                    setLoading(false);
                    toast.error("Something went wrong");
                    console.log(e);
                });
            }
        }
        else {
            setLoading(false);
        }
    }

    function handleErrors(data) {
        let errors = null;
        if (data.title == '') {
            errors = true;
            setErrors({ ...errors, 'title': 'Title is required' })
        }
        else if (data.description == '') {
            errors = true;
            setErrors({ ...errors, 'description': 'Description is required' })
        }
        else if (data.files != "") {
            const validExtensions = ['jpg', 'jpeg', 'png', 'csv', 'doc', 'html', 'xml', 'zip', 'pdf', 'mp4'];
            for (let i = 0; i < data.files.length; i++) {
                const extension = data.files[i].name.split('.').pop().toLowerCase();
                if (!validExtensions.includes(extension)) {
                    errors = true;
                    setErrors({ ...errors, 'file': 'File extension is not allowed' });
                }
            }
        }
        return errors;
    }

    const handleClick = (e) => {
        const name = e.target.name;
        const user_id = e.target.value;
        const id = e.target.id;
        if (e.target.checked == false) {
            setUserIds([...userIds, user_id]);
        }
        else {
            var newArray = userIds.filter((value) => value != user_id);
            setUserIds(newArray);
        }
    }

    const handleSelector = () => {
        API.post('/admin/addMemberInProject', selected)
            .then((response) => {
                toast("A member is successfully added");
                setSelected([]);
                getTeamAndWaitingMembers();
            }).catch((error) => {
                console.log(error);
            });
    }
    const AddMembersPopup = () => setShowAddTeamMember(true);
    const closeAddMembersPopup = () => setShowAddTeamMember(false);
    useEffect(() => {
        if (selected.length > 0) {
            handleSelector();
        }
        else {
            if (discussion_id) {
                dispatch(pageTitle(title));
                editDiscussion()
            }
            else {
                getTeamAndWaitingMembers();
            }
        }
    }, [selected])

    return (
        <div className='main-dashboard-admin'>
            {loading &&
                <div className='rotate-line-loader'>
                    <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
                </div>}
            <button className='btn btn-primary mb-2 btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
            <div className='add-distext-btn'>
                <h2>New Discussion D - {(lastDiscussion) ? lastDiscussion + 1 : 1}</h2>
                <Link to={`/clients/client-detail/project-detail/discussions/${id}`}>View Discussion List</Link>
            </div>
            <div className='add-discussion'>
                <div className='discuss-edit left'>
                    <div className='discuss-edit-inner'>
                        <div className='discuss-input'>
                            <input type="text" placeholder="Discussion Title" name="title" onChange={handleChange} value={formData.title} /></div>
                        <span className='text-danger'>{errors?.title}</span>
                        <CKEditor
                            editor={ClassicEditor}
                            data={formData.description}
                            onChange={(event, editor) => {
                                setFormData({ ...formData, description: editor.getData() });
                            }}
                        />
                        <span className='text-danger'>{errors?.description}</span>
                        <div className="upload-btn-wrapper mt-3">
                            <button className="attached-btn">
                                <i className="fas fa-paperclip"></i> Attach Files
                            </button>
                            <input type="file" name="files" onChange={handleChangeFiles} multiple />
                            <span className="text-danger">{errors?.file}</span>

                            {/* Display selected file names */}
                            <div className="file-names mt-2">
                                {selectedFiles.map((file, index) => (
                                    <div key={index}>{file.name}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div class="form-btn-rows filed-btns">
                        <button class="global-icon-btn orange-btn" onClick={handleSubmit}>
                            <i class="fas fa-save"></i>{discussion_id ? 'Update' : 'Save'}</button>
                        <Link to={`/clients/client-detail/project-detail/discussions/${id}`} type="button" class="global-icon-btn">
                            <i class="fas fa-times"></i>Cancel</Link>
                    </div>
                </div>
                {
                    !discussion_id ?
                        <div className='discuss-edit right'>
                            <div className="password-card check">
                                <div className='psw-tems-btns'>
                                    <div className="psw-team-btn">
                                        <button>Team</button>
                                    </div>
                                    <div className='disc-edit-txt'>
                                        <p>Check to enable email alerts.</p>
                                    </div>
                                </div>
                                <div className="pass-check-in">
                                    {
                                        Object.keys(teamMembers).length > 0 ?
                                            teamMembers?.users.length > 0 ?
                                                <div className="row">
                                                    {
                                                        teamMembers?.users.map((team) =>
                                                            <div className="pass-check">
                                                                <input type="checkbox" name='userId' value={team.id} onChange={handleClick} checked={(userIds.includes(`${team.id}`)) ? false : true} />
                                                                <div className="project-peo-img"><img src={team?.profilePictureUrl} />
                                                                </div>
                                                                <div className="project-peo-cont">
                                                                    <p> {team?.firstName + ' ' + team?.lastName + ' - ' + team?.email}</p></div></div>
                                                        )
                                                    }
                                                </div>
                                                : 'No team member found'
                                            : 'No team member found'
                                    }
                                    <div className="add-mem">
                                    <a href="javascript:void(0)" onClick={showAddTeamMember ? closeAddMembersPopup : AddMembersPopup}>
                                        {
                                            showAddTeamMember ?
                                                <i className="fa fa-window-close"></i>
                                                :
                                                <i className="fas fa-plus"></i>
                                        }
                                        <span>Invite More Team Members</span>
                                    </a>

                                        
                                        {
                                            showAddTeamMember ?
                                                <MultiSelect
                                                    options={options}
                                                    value={selected}
                                                    onChange={setSelected}
                                                    labelledBy="sadsa"
                                                 />
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''
                }
            </div>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
        </div>
    )
}

export default AddDiscussion