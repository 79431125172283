import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import { Link } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import TimeAgo from '../clients/TimeAgo';

function EmailLogs() {
    const dispatch = useDispatch();
    const [emailLogs, setEmailLogs] = useState([]);
    const [filteredEmailLogs, setFilteredEmailLogs] = useState([]);
    const [formData, setFormData] = useState({ name: '', service_type: '' });
    const [searchQuery, setSearchQuery] = useState('');
    const title = 'Email Log List';
    const [loading, setLoading] = useState(false);

    // Utility function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
        const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        return `${formattedDate} ${formattedTime}`;
  };

    const allEmailLogs = () => {
        setLoading(true);
        API.get('/admin/emailLogs')
            .then((response) => {
                setLoading(false);
                setEmailLogs(response.data.data);
                setFilteredEmailLogs(response.data.data); // Initialize filtered logs with all logs
            }).catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSearch = () => {
        const filteredLogs = emailLogs.filter(log =>
            log.receiverName.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredEmailLogs(filteredLogs);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        API.post('/admin/filter-email-logs', formData)
            .then((response) => {
                setLoading(false);
                setEmailLogs(response.data.data);
                setFilteredEmailLogs(response.data.data); // Update filtered logs when logs change
            }).catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    useEffect(() => {
        dispatch(pageTitle(title));
        allEmailLogs();
    }, []);

    useEffect(() => {
        // Apply search filter whenever searchQuery changes
        const filteredLogs = emailLogs.filter(log =>
            log.receiverName.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredEmailLogs(filteredLogs);
    }, [searchQuery, emailLogs]); // Re-run effect whenever searchQuery or emailLogs change

    return (
        <div className="main-dashboard-admin">
             <div class="breadcrumbs">
                <Link to={"/"}>Home </Link><span><i class="fa fa-chevron-right"></i> 
                <Link className='sr-active-bread' to={"/email-logs"} > Email Logs </Link></span>
            </div>
              {loading &&
            <div className='rotate-line-loader'>
                 <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
            </div>}
            <div className="checklist-header-wrap email-logs">
                <div className="selectValues">
                    <div className="fields">
                    <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>

                        <input
                            type="text"
                            placeholder="Receiver Name"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                   
                </div>
            </div>
            <div className="teamInfoTable email-table">
                <table border="0" width="100%">
                    <thead>
                        <tr>
                            <th className="name">Receiver Name</th>
                            <th className="projectName">Project Name</th>
                            <th className="message">Message</th>
                            <th className="createdAt">Date & Time Set</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredEmailLogs.length > 0 ? (
                            filteredEmailLogs.map((item, index) => (
                                <tr key={index}>
                                    <td className="name">{item.receiverName}</td>
                                  <td className="projectName">{item.projectName}</td>
                                    <td className="message">{item.message.substring(0, 20)}</td>
                                    <td className="createdAt">{formatDate(item.createdAt)}</td>
                                    <td className='view'><Link to={`emaillogdetails/${item.id}`}>View</Link></td>
                                </tr>
                            ))
                        ) : (
                            <td colSpan={6}>
                            <div class="no-data-found no-padding"><span>No logs found.</span></div></td>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default EmailLogs;
