import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import NewProjectType from './addNewProjectType';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';
import { Link } from 'react-router-dom';


import {
  MRT_TableContainer,
  useMaterialReactTable,
} from 'material-react-table';

function Checklists() {
  const dispatch = useDispatch();
  const title = "Checklists";
  const [ProjectTypes, setProjectTypes] = useState(null);
  const [Checklistdata, setChecklist] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [SelectedChecklist, setSelectedChecklist] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [tasks, setTasks] = useState([{ id: 1, value: '' }]);
  const [checklistId, setChecklistId] = useState(null);
  const [tasksDetail, setTasksDetail] = useState(null);
  const [createTask, setCreateTask] = useState(null);
  const [taskIdCounter, setTaskIdCounter] = useState(1);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState(null);
  const [key, setKey] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editingTask, setEditingTask] = useState({ id: null, value: '' });
  const [modalClosed, setModalClosed] = useState(false);
  const [loading, setLoading] = useState(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        Cell: ({ row }) => (
          <div className="sr-edit-delete">
            <button className='sr-cstm-orange global-icon-btn orange-btn' onClick={() => handleEditTask(row.original)}>Edit</button>
            <button className='sr-global-icon-btn' onClick={() => handleDelete(row.original.id)}>Delete</button>
          </div>
        ),
      },
    ],
    []
  );

  const [data, setData] = useState([]);

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data,
    enableRowOrdering: true,
    enableSorting: true,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          const newData = [...data];
          newData.splice(
            hoveredRow.index,
            0,
            newData.splice(draggingRow.index, 1)[0],
          );
          setData(newData);
          updateTaskSerial(newData);
        }
      },
    }),
  });

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const getProjectTypes = () => {
    API.get('/admin/project-types')
      .then((response) => {
        setProjectTypes(response.data.data);
      }).catch((error) => {
        console.log(error);
      })
  }

  const getChecklist = (projectId) => {
    setLoading(true);
    API.get(`/admin/projects/${projectId}/checklists`)
      .then((response) => {
        setLoading(false);
        setChecklist(response.data);
      }).catch((error) => {
        setLoading(false  );
        console.log(error);
      });
  };

  const handleSelectChange = (e) => {
    const projectId = e.target.value;
    setChecklist(null);
    setSelectedProjectId(projectId);
    getChecklist(projectId);
    setCreateTask(null);
  };

  const handleChangeChecklist = (event) => {
    const { name, value } = event.target;
    setChecklistId(value);
    API.get(`admin/getChecklistTasks/${value}`)
      .then((response) => {
        setCreateTask(true);
        setData(response.data.tasks);
      }).catch((e) => {
        console.log(e);
      });
  };

  const getTasks = () => {
    API.get(`admin/getChecklistTasks/${checklistId}`)
      .then((response) => {
        setData(response.data.tasks);
      }).catch((e) => {
        console.log(e);
      });
  }

  const updateTaskSerial = (taskSerial) => {
    const data = [];
    taskSerial.forEach((tasks) => {
      data.push({ id: tasks.id, serialNo: tasks.serialNo });
    });
    const token = localStorage.getItem('token');
    API.post('/admin/setSerialTasks', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      console.log(response.data.message);
    }).catch((e) => {
      console.log(e);
    });
  }

  const handleInputChange = (id, value) => {
    const updatedTasks = tasks.map(task =>
      task.id === id ? { ...task, value } : task
    );
    setTasks(updatedTasks);
  };

  const addTaskRow = () => {
    const newTask = { id: taskIdCounter + 1, value: '' };
    setTasks([...tasks, newTask]);
    setTaskIdCounter(taskIdCounter + 1);
  };

  const handleRemoveTask = (id) => {
    const updatedTasks = tasks.filter(task => task.id !== id);
    setTasks(updatedTasks);
  };

  const handleSubmit = () => {
    let blank = null;
    tasks.forEach(element => {
      if (element.value === '') {
        blank = true;
      }
    });
    if (!blank) {
      const token = localStorage.getItem('token');
      API.post(`admin/getChecklistTasks/${checklistId}`, tasks, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then((response) => {
          getTasks();
          setTasks([{ id: 1, value: '' }]);
          setErrors('');
        }).catch((e) => {
          console.log(e);
        });
    } else {
      setErrors({ blank: 'Please fill all tasks' });
    }
  };

  const handleDetails = (task) => {
    console.log('Details for task:', task);
  };

  const handleDelete = (taskId) => {
    const token = localStorage.getItem('token');
    API.delete(`admin/task/${taskId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => {
        // Notify user of success
        toast.success('Task deleted successfully');
  
        // Refresh the tasks list
        getTasks();
      }).catch((e) => {
        // Notify user of failure
        toast.error('Failed to delete task');
        console.log(e);
      });
  };
  

  const handleEditTask = (task) => {
    setEditingTask({ id: task.id, value: task.description });
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setEditingTask({ id: null, value: '' });
    setIsEditing(false);
  };

  const handleSaveEdit = () => {
    const token = localStorage.getItem('token');
    API.put(`admin/task/${editingTask.id}`, { description: editingTask.value }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => {
        toast.success('Task updated successfully');
        getTasks();
        setEditingTask({ id: null, value: '' });
        setIsEditing(false);
      }).catch((e) => {
        toast.error('Failed to update task');
        console.log(e);
      });
  };

  useEffect(() => {
    dispatch(pageTitle(title));
    getProjectTypes();
  }, []);

  useEffect(() => {
    if (!show && modalClosed) {
      getProjectTypes();
      if (selectedProjectId) {
        getChecklist(selectedProjectId);
      }
      setModalClosed(false);
    }
  }, [show, modalClosed]);

  const handleClose = () => {
    setShow(false);
    setModalClosed(true);
    getProjectTypes();
  };

  return (
    <div className="main-dashboard-admin" key={key}>
         <div class="breadcrumbs">
                <Link to={"/"}>Home </Link><span><i class="fa fa-chevron-right"></i> 
                <Link className='sr-active-bread' to={"/checklists"} > Checklists </Link></span>
            </div>
        {loading &&
            <div className='rotate-line-loader'>
                 <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
            </div>}
      <div className="checklist-header-wrap">
        <div className="selectValues">
          <div className="fields">
            <label htmlFor="">Project Types</label>
            <div className="selectDropdown">
              <select name="Opportunity" id="Opportunity" onChange={handleSelectChange}>
                <option>Select Project Type</option>
                {ProjectTypes && ProjectTypes.map((item) =>
                  <option key={item.id} value={item.id}>{item.name}</option>
                )}
              </select>
              <div className="dropIcon">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988">
                  <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path>
                </svg>
              </div>
            </div>
          </div>
          <div className="fields">
            <label htmlFor="">Checklists:</label>
            <div className="selectDropdown">
              <select name="Checklist" id="Checklists" onChange={handleChangeChecklist} >
                <option>Select checklist</option>
                {Checklistdata && Checklistdata.map(item =>
                  <option key={item.id} value={item.id}>{item.name}</option>
                )}
              </select>
              <div className="dropIcon">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988">
                  <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path>
                </svg>
              </div>
            </div>
          </div>
          <span className="global-icon-btn addFieldButton" onClick={(e) => setShow(true)}>
            <img src="assets/images/plus.svg" alt="" />
          </span>
        </div>
        <NewProjectType
          show={show}
          setShow={setShow}
          handleClose={handleClose} // Pass handleClose to NewProjectType
        />
      </div>
      {
        createTask ?
          <div className="tasks-section-outer">
            <div className="tasks-section-inner">
              <div className="header-task-section">
                <div className="heading-task-section">
                  <h3>Tasks:</h3>
                </div>
                {!isEditing && <a className="plus-icon-tasks" onClick={addTaskRow}>+</a>}
              </div>
              <p className='text-danger'>
                {errors ? errors.blank : ''}
              </p>
              <div className='sr-sub-inp'>
                {isEditing ? (
                  <div>
                    <input
                      type="text"
                      value={editingTask.value}
                      onChange={e => setEditingTask({ ...editingTask, value: e.target.value })}
                    />
                    <div className="button-global-submit">
                      <button className="global-icon-btn orange-btn" onClick={handleSaveEdit} >Update</button>
                      <button className="global-icon-btn" onClick={handleCancelEdit} >Cancel</button>
                    </div>            
                  </div>
                ) : (
                  <>
                    {tasks.map(task => (
                      <div key={task.id} className="task-input-row">
                        <input
                          type="text"
                          value={task.value}
                          onChange={e => handleInputChange(task.id, e.target.value)}
                        />
                        {tasks.length > 1 && <a  onClick={() => handleRemoveTask(task.id)}>
                        <i className="fas fa-times"></i></a>}  
                      </div>
                    ))}
                    <div className="button-global-submit">
                      <button className='global-icon-btn orange-btn' onClick={handleSubmit}>Submit</button>
                    </div>      
                  </>
                )}
              </div>
              {data ?
                <MRT_TableContainer table={table} />
                : ''
              }
            </div>
          </div>
          : ''
      }
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick  pauseOnFocusLoss draggable pauseOnHover theme="light" />
    </div>
  );
}

export default Checklists;
