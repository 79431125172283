import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const KanbanCard = ({ task, onDragEnd }) => {
  const [collapsed, setCollapsed] = useState(true);
  console.log('task', task);

  return (
    <div
    className={`sr-cstm-column-wrap ${task.issue ? 'sr-issue-present' : ''}`}
    draggable={true}
      onDragStart={(e) => e.dataTransfer.setData('taskId', task.id)} 
      onDragEnd={(e) => onDragEnd(e, task)}>
      {task.issue && <span className="sr-issue-flag">Issue</span>}
      <div className='task-head-main'>
        
        <div className='task-head'>
          <svg xmlns="http://www.w3.org/2000/svg" width="14.5" height="14.5" viewBox="0 0 14.5 14.5">
            <path id="Icon_material-speaker-notes" data-name="Icon material-speaker-notes" d="M16.05,3H4.45A1.448,1.448,0,0,0,3.007,4.45L3,17.5l2.9-2.9H16.05a1.454,1.454,0,0,0,1.45-1.45V4.45A1.454,1.454,0,0,0,16.05,3Zm-8.7,8.7H5.9V10.25H7.35Zm0-2.175H5.9V8.075H7.35Zm0-2.175H5.9V5.9H7.35Zm5.075,4.35H8.8V10.25h3.625ZM14.6,9.525H8.8V8.075h5.8Zm0-2.175H8.8V5.9h5.8Z" transform="translate(-3 -3)" fill="#083d78" />
          </svg>  
         <Link to={`/taskDetailPage/${task.id}`} ><span>{task.taskTitle}</span></Link> 
        </div>
       <span
          className='sr-cstm-task-col'
          onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <img src='/assets/images/down.png' /> : <img src='/assets/images/up-arrow.png' />}
        </span>
      </div>
      {!collapsed && (
        <div className='sr-task-description'>
          <strong>Description: </strong><p>{task.taskDescription}</p><br />
          <Link to={`/taskDetailPage/${task.id}`} >View More <i class="fa fa-arrow-right" aria-hidden="true"></i>
          </Link> 
        </div>
      )}
    </div>
  );
};

export default KanbanCard;
