import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Layouts from './layouts/Layouts';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ClientDashboard from './components/dashboard/ClientDashboard';
import ClientDetail from './components/clients/ClientDetail';
import ClientsList from './components/clients/ClientsList';
import ProjectDetail from './components/clients/ProjectDetail';
import OpportunityList from './components/opportunity/OpportunityList';
import AddOpportunity from './components/opportunity/AddOpportunity';
import Teams from './components/teams/Teams';
import AddTeam from './components/teams/AddTeam';
import TeamMemberDetail from'./components/teams/TeamMemberDetail';
import Checklists from'./components/checklist/Checklists';
import Details from './components/opportunity/Details';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import AddSop from './components/SOPs/AddSop';
import SopDetail from './components/SOPs/SopDetail';
import Sop from './components/SOPs/Sop';
import EmailLogs from './components/Logs/EmailLogs';
import EmailLogsDetail from './components/Logs/EmailLogsDetail';
import PasswordVault from './components/clients/PasswordVault';
import Discussions from './components/clients/Discussions';
import AddDiscussion from './components/clients/AddDiscussion';
import DiscussionDetail from './components/clients/DiscussionDetail';
// import Checklist from './components/checklist/Checklists';
import TimeTracking from './components/timeTracking/TimeTrackingListing';
import API from './AxiosConfig';
import AcceptInvite from './components/opportunity/acceptingInvitation';
// import Board from './components/managementBoard/src/components/index';
// import { Header, Board, EmptyBoard } from "./components/managementBoard/src/components";
import ArchiveProject from './components/clients/archivedProjects';
import AllActivityLogs from './components/opportunity/ActivityLogs/ViewAllLogs';
import AddClientProject from './components/clients/AddClientProject';
import Progress from './components/progress/Progress';
import MyProfile from './components/myProfile';
import ManagePermissions from './components/managePermissions';
import ActivityLogs from './components/Logs/ActivityLogs';
import SearchDetails from './components/searchDetails';
import ControlledBoard from './components/Board';
import KanbanBoard  from './components/Board/index';
import AddManualTime from './components/timeTracking/addManualTime';
import TaskDetailPage from './components/Board/taskDetailPage';
import ArchivedTaskPage from './components/Board/archivedTaskPage';
import OpportunityData from './components/clients/OpportunityData';
import TimeTrackingforProject from './components/timeTracking/ParticularProject/timeTrackingforProject';
import ViewChecklist from './components/clients/viewChecklist';

function ProtectedRoute({ element, allowedRole }) {
  const navigate = useNavigate();
  useEffect(() => {
    checkJWTToken()
    .then((isAuthenticated) => {
      if (isAuthenticated) {
        // Redirect to the login page if not authenticated
        navigate("/", { replace: true });
      }
    })
    .catch((error) => {
      console.error("JWT token check failed:", error);
      // Redirect to the login page or handle the error as needed
      navigate("/", { replace: true });
    });
  }, [navigate]);
  return element;
}

function checkJWTToken() {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');

    if (!token) {
      resolve(false);
      return;
    }

    API.post('/verify-token', { "token": token })
      .then((response) => {
        console.log("adasadasasda");
        if (response.status === 200) {
          resolve(true);
        } else if (response.status === 202 || (response.data.error && response.data.error.name === 'TokenExpiredError')) {
          localStorage.removeItem('token');
          resolve(false); 
        } else {
          console.log(response.data.message);
          resolve(false);
        }
      })
      .catch((error) => {
        console.error("JWT token check failed:", error);
        localStorage.clear();
        reject(error);
      });
  });
}



function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [currentToken, setCurrentToken] = useState('');
  const getCurrentToken = localStorage.getItem('token');

  if (!currentToken && getCurrentToken) {
    setCurrentToken(getCurrentToken);
  }

  useEffect(() => {
    if (currentToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [currentToken]);

  const handleLogin = (token) => {
    setCurrentToken(token);

  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login handleLogin={handleLogin} isLoggedIn={isLoggedIn} />} />
          <Route element={<Layouts isLoggedIn={isLoggedIn} handleLogin={handleLogin} />}>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/clients' element={<ClientsList />} />
            <Route path='/clients/client-detail/:id' element={<ClientDetail />} />
            <Route path='/clients/archive-client-detail/:id' element={<ArchiveProject />} />
            <Route path='/clients/client-detail/project-detail/:id' element={<ProjectDetail />} />
            <Route path='/clients/client-detail/project-detail/password-vault/:id' element={<PasswordVault />} />
            <Route path='/clients/client-detail/project-detail/discussions/:id' element={<Discussions />} ></Route>
            <Route path='/clients/client-detail/project-detail/discussions/discussion-detail/:id' element={<DiscussionDetail />} />
            <Route path='/clients/client-detail/project-detail/discussions/new-discussion/:id/:discussion_id?' element={<AddDiscussion />} ></Route>
            <Route path='/opportunities' element={<OpportunityList />} />
            <Route path='/team' element={<Teams />} />
            <Route path='/addteam' element={<AddTeam />} />
            <Route path='/update-team/:id' element={<AddTeam />} />
            <Route path='/opportunities/details/:id' element={<Details />} />
            <Route path='/opportunities/addopportunity' element={<AddOpportunity />} />
            <Route path='/teamMember-detail/:id' element={<TeamMemberDetail />} />
            <Route path='/sop/:projectTypeId?' element={<Sop />} />
            <Route path='/add-sop/:projectId?/:id?' element={<AddSop/>}></Route>
            <Route path='sop-detail/:projectId?/:id?' element={<SopDetail/>}></Route>
            <Route path='/checklists' element={<Checklists />} />
            <Route path='/email-logs' element={<EmailLogs />} />
            <Route path='/email-logs/emaillogdetails/:id' element={< EmailLogsDetail />} />
            <Route path='/log-detail/:id' element={<EmailLogsDetail />} />
            <Route path='/timeTracking' element={<TimeTracking />} />
            {/* <Route path='/board/:id' element={<Board />} /> */}
            <Route path='/view-activities/:projectid' element={<AllActivityLogs />} />
            <Route path='/clients/add-new-Project/:id' element={<AddClientProject />} />
            <Route path='/progress' element={<Progress />} />
            <Route path='/myProfile' element={<MyProfile />} />
            <Route path='/managePermissions' element={<ManagePermissions />} />
            <Route path='/viewAll/Activity' element={< ActivityLogs />}/>
            <Route path='/searchedData' element={<SearchDetails />} />
            <Route path='/clients/client-detail/project-detail/:id' element={<ProjectDetail />} />
            <Route path='/clients/client-detail/project-detail/password-vault/:id' element={<PasswordVault />} />
            <Route path='/viewBoard/:id' element={<KanbanBoard />} />
            <Route path='/addManualTime' element={<AddManualTime />} />
            <Route path='/taskDetailPage/:taskId' element={<TaskDetailPage />} />
            <Route path='/archivedTask/:id' element={<ArchivedTaskPage />} />
            <Route path='/clients/client-detail/project-detail/opportunityDetails/:id' element={<OpportunityData />} />
            <Route path='/timeTracking/:projectId' element={<TimeTrackingforProject />} />
            <Route path='/projectDetail/:projectId/:checklistId' element={<ViewChecklist />} />
          </Route>
          <Route path='/forgot-password' element={<ProtectedRoute element={<ForgotPassword />} />}  />
          <Route path='/reset-password/:token' element={<ResetPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
