import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../features/actions/pageTitleActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../AxiosConfig';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import InputMask from 'react-input-mask';
import { useParams, Link ,useNavigate } from 'react-router-dom'

function AddManualTime() {
    const [Projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedProjectId, setSelectedProjectId] = useState(null); 
    const [tasks, setTasks] = useState([]); 
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [showTasks, setShowTasks] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const userData = useSelector(state => state.auth.user);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const today = new Date(); 

    const handleTaskChange = (event) => {
        setSelectedTaskId(event.target.value);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleProjectChange = async (event) => {
        const projectId = event.target.value;
        setSelectedProjectId(projectId);

        if (projectId && projectId !== 'all') {
            try {
                const response = await API.get(`/timeTracking/getTheTaskDetails/${projectId}`); 
                if (response && response.data) {
                    if(response.status == 201) {
                        setTasks([]);
                        console.log("sdfsdfsdsdsdf");
                    }
                    setTasks(response.data); 
                    console.log(response.data); 
                } else {
                    toast.error('Error while getting the project data', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const validateForm = () => {
        const billableHours = document.querySelector('input[name="billableHours"]').value;
        const nonBillableHours = document.querySelector('input[name="nonBillableHours"]').value;
        const description = document.querySelector('textarea[name="description"]').value;

        let formErrors = {};
        let isValid = true;

        if (!selectedProjectId) {
            formErrors.project = 'Please select a project';
            isValid = false;
        }

        if (!selectedDate) { 
            formErrors.date = 'Please select a date';
            isValid = false;
        }

        if (billableHours.trim() === '' && nonBillableHours.trim() === '') {
            formErrors.hours = 'Please enter hours';
            isValid = false;
        }

        


        if (description.trim() === '') {
            formErrors.description = 'Please enter a description';
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSave = async () => {
        if (!validateForm()) {
            return; // Prevent form submission if validation fails
        }
        const billableHours = document.querySelector('input[name="billableHours"]').value;
        const nonBillableHours = document.querySelector('input[name="nonBillableHours"]').value;
        const description = document.querySelector('textarea[name="description"]').value;

        try {
            const response = await API.post(`/timeTracking/timeTracking/${selectedProjectId}`, {
                projectId: selectedProjectId,
                date: selectedDate,
                billableHours,
                nonBillableHours,
                description,
                taskId: selectedTaskId,
                userID : userData.user.id
            });
            if (response.data.success) {
                toast.success('Data saved successfully', {
                    position: toast.POSITION.TOP_CENTER,
                });
                setSelectedProjectId(null);
                setSelectedDate(null);
                setSelectedTaskId(null);
                setIsChecked(false);
                document.querySelector('input[name="billableHours"]').value = '';
                document.querySelector('input[name="nonBillableHours"]').value = '';
                document.querySelector('textarea[name="description"]').value = '';
                setTasks([]);
            } else {
                toast.error('Error while saving data', {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } catch (error) {
            console.error(error);
            toast.error('Error while saving data', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    const dispatch = useDispatch();
    const title = 'Add Manual Time';

    const getProjects = async () => {
        try {
            const response = await API.get('admin/project');
            if (response && response.data) {
                setProjects(response.data);
            } else {
                toast.error('Error while getting the data', {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        dispatch(pageTitle(title));
        getProjects();
    }, [dispatch, title]);

    return (
        <div className="main-dashboard-admin timeTracking-manual">
            <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
            <div className="checklist-header-wrap">
                <div className='timeTracking-manual-inner'>
                    <div className="selectValues">
                        <div className='slect-filed'>
                            <div className="fields week cmn-field">
                                <label>Select Project</label>
                                <div className="selectDropdown">
                                    <select name="Opportunity" id="Checklists" onChange={handleProjectChange}>
                                        <option value="all">Select Project</option>
                                        {Projects.map((project) => (
                                            <option key={project.id} value={project.id}>
                                                {project.opportunityName}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="dropIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988">
                                            <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path>
                                        </svg>
                                    </div>
                                </div>
                                {errors.project && <div className="error-message">{errors.project}</div>}

                            </div>
                            <div className="getDetails cmn-field">
                                <label>Select Date</label>
                                <div className="dateBlock">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.589" height="17.816" viewBox="0 0 15.589 17.816">
                                        <path id="Icon_awesome-calendar-alt" data-name="Icon awesome-calendar-alt" d="M0,16.146a1.671,1.671,0,0,0,1.67,1.67H13.919a1.671,1.671,0,0,0,1.67-1.67V6.681H0Zm11.135-6.82a.419.419,0,0,1,.418-.418h1.392a.419.419,0,0,1,.418.418v1.392a.419.419,0,0,1-.418.418H11.553a.419.419,0,0,1-.418-.418Zm0,4.454a.419.419,0,0,1,.418-.418h1.392a.419.419,0,0,1,.418.418v1.392a.419.419,0,0,1-.418.418H11.553a.419.419,0,0,1-.418-.418ZM6.681,9.326A.419.419,0,0,1,7.1,8.908H8.49a.419.419,0,0,1,.418.418v1.392a.419.419,0,0,1-.418.418H7.1a.419.419,0,0,1-.418-.418Zm0,4.454a.419.419,0,0,1,.418-.418H8.49a.419.419,0,0,1,.418.418v1.392a.419.419,0,0,1-.418.418H7.1a.419.419,0,0,1-.418-.418ZM2.227,9.326a.419.419,0,0,1,.418-.418H4.036a.419.419,0,0,1,.418.418v1.392a.419.419,0,0,1-.418.418H2.645a.419.419,0,0,1-.418-.418Zm0,4.454a.419.419,0,0,1,.418-.418H4.036a.419.419,0,0,1,.418.418v1.392a.419.419,0,0,1-.418.418H2.645a.419.419,0,0,1-.418-.418ZM13.919,2.227h-1.67V.557A.558.558,0,0,0,11.692,0H10.578a.558.558,0,0,0-.557.557v1.67H5.567V.557A.558.558,0,0,0,5.011,0H3.9A.558.558,0,0,0,3.34.557v1.67H1.67A1.671,1.671,0,0,0,0,3.9v1.67H15.589V3.9A1.671,1.671,0,0,0,13.919,2.227Z"></path>
                                    </svg>
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="mm/dd/yyyy"
                                        maxDate={today}
                                        customInput={
                                            <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                                              {(inputProps) => <input {...inputProps} />}
                                            </InputMask>
                                          }
                                    />
                                </div>
                                {errors.date && <div className="error-message">{errors.date}</div>}

                            </div>
                            <div className="cmn-field">
                                <label>Billable Hours</label>
                                <input type="number" name="billableHours"  min="0" 
        max="24" 
        step="0.5"  
        onBlur={(e) => {
            let value = parseFloat(e.target.value) || 0;
            // Restrict value between 0 and 24
            value = Math.max(0, Math.min(24, value));
            // Format to two decimal places only after blur (when input is done)
            e.target.value = value.toFixed(2);
        }}/>
                                {errors.hours && <div className="error-message">{errors.hours}</div>}

                            </div>
                            <div className="cmn-field">
    <label>Non-Billable Hours</label>
    <input 
        type="number" 
        name="nonBillableHours" 
        min="0" 
        max="24" 
        step="0.5"  // Accept decimal values
        className="form-control" 
        onBlur={(e) => {
            let value = parseFloat(e.target.value) || 0;
            // Restrict value between 0 and 24
            value = Math.max(0, Math.min(24, value));
            // Format to two decimal places only after blur (when input is done)
            e.target.value = value.toFixed(2);
        }}
    />
    {errors.hours && <div className="error-message">{errors.hours}</div>}
</div>

                        </div>
                        <div className='time-manual-textarea'>
                            <div className='label-text'>
                                <label>Description of Work</label>
                                <div className='sr-cstm-billing-wrap' >
                                <div className="field">
                                    {tasks.length > 0 && (
                                        <>
                                            <input 
                                                type="checkbox" 
                                                name="servicesInterested" 
                                                className="custom-checkbox" 
                                                checked={isChecked} 
                                                onChange={handleCheckboxChange}  
                                            />
                                            <span>Link this to a task</span>
                                        </>
                                    )}
                                </div>
                                {isChecked && tasks.length > 0 && (
                                    <div className="selectDropdown">
                                    <select  name="Opportunity" id="Checklists" onChange={handleTaskChange}>
                                        <option value="all">Select Task</option>
                                        {tasks.map(task => (
                                            <option key={task.id} value={task.id}>
                                                {task.taskTitle}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="dropIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988"><path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path></svg></div>
                                    </div>
                                )}
                                </div>
                            </div>
                            <textarea name="description" className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            {errors.description && <div className="error-message">{errors.description}</div>}

                            <div className="buttons-editor">
                                <button 
                                    href="javascript:void(0)" 
                                    className="global-icon-btn orange-btn" 
                                    type="button" 
                                    onClick={handleSave}
                                >
                                    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"></path>
                                    </svg>
                                    Save
                                </button>
                                <Link to={'/timeTracking'} className="global-icon-btn" >
                                    <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                                    </svg>
                                    Discard
                                    </Link>
                            </div>
                        </div>
                    </div>
                    {/* <div className='timetrack-btns'>
                        <a href="javascript:void(0)">View Time Tracking</a>
                        <a href="javascript:void(0)">Monthly Report</a>
                    </div> */}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default AddManualTime;
