import { useState } from 'react';
import { toast } from 'react-toastify';
import API from '../../AxiosConfig';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';

function AddNewCompany({ show, setShow, onClose }) {
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState("");
  const navigate = useNavigate();
  const userData = useSelector(state => state.auth.user);

  const changeHandler = (event) => {
    const inputValue = event.target.value;
    setCompanyName(inputValue);
    if (inputValue.trim() === "") {
      setError("Company name is required.");
    } else {
      setError("");
    }
  };

  const phoneNumberChangeHandler = (event) => {
    const inputValue = event.target.value;
    setPhoneNumber(inputValue);
    if (!/^\d{10}$/.test(inputValue)) {
      setPhoneNumberError("Please enter a valid phone number.");
    } else {
      setPhoneNumberError("");
    }
  };

  const emailChangeHandler = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
    if (!/^\S+@\S+\.\S+$/.test(inputValue)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const checkboxChangeHandler = (event) => {
    setIsAgreed(event.target.checked);
  };

  const contentChangeHandler = (event) => {
    const inputValue = event.target.value;
    setContent(inputValue);
    if (inputValue.trim() === "") {
      setContentError("Content is required when announcement bar is checked.");
    } else {
      setContentError("");
    }
  };

  const saveHandler = async () => {
    let valid = true;
    const token = sessionStorage.getItem('token');
    if (companyName.trim() === "") {
      setError("Company name is required.");
      valid = false;
    } else {
      setError("");
    }

    if (!/^\d{10}$/.test(phoneNumber)) {
      setPhoneNumberError("Please enter a valid phone number.");
      valid = false;
    } else {
      setPhoneNumberError("");
    }

    if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError("Please enter a valid email address.");
      valid = false;
    } else {
      setEmailError("");
    }

    if (isAgreed && content.trim() === "") {
      setContentError("Content is required when announcement bar is checked.");
      valid = false;
    } else {
      setContentError("");
    }

    if (!valid) {
      return;
    }

    try {
      const response = await API.post(`/admin/company-names`, {
        name: companyName,
        phoneNumber: phoneNumber,
        email: email,
        announcement: isAgreed,
        content: content,
        user: userData.user.id
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success(response?.data?.message, {
        position: "top-center",
      });
      setShow(false);
      setCompanyName("");
      setPhoneNumber("");
      setEmail("");
      setIsAgreed(false);
      setContent("");
      if (onClose) {
        onClose(); // Call the callback function
      }
    } catch (e) {
      console.log(e);
      if (e.response?.status === 302) {
        setError("Company name already exists.");
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    if (onClose) {
      onClose(); // Call the callback function
    }
  };

  return (
    <>
      <Modal
        centered
        className="fade main-popup-section"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <h4 className="modal-title">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19.2" viewBox="0 0 24 19.2">
              <path id="Icon_awesome-user-plus" data-name="Icon awesome-user-plus" d="M23.4,7.8H21V5.4a.6.6,0,0,0-.6-.6H19.2a.6.6,0,0,0-.6.6V7.8H16.2a.6.6,0,0,0-.6.6V9.6a.6.6,0,0,0,.6.6h2.4v2.4a.6.6,0,0,0,.6.6h1.2a.6.6,0,0,0,.6-.6V10.2h2.4a.6.6,0,0,0,.6-.6V8.4A.6.6,0,0,0,23.4,7.8ZM8.4,9.6A4.8,4.8,0,1,0,3.6,4.8,4.8,4.8,0,0,0,8.4,9.6Zm3.36,1.2h-.626a6.528,6.528,0,0,1-5.467,0H5.04A5.041,5.041,0,0,0,0,15.84V17.4a1.8,1.8,0,0,0,1.8,1.8H15a1.8,1.8,0,0,0,1.8-1.8V15.84A5.041,5.041,0,0,0,11.76,10.8Z" />
            </svg>
            Add Company
          </h4>
        </Modal.Header>
        <div className="Company-popup-content">
          <Modal.Body>
            <div className="team-member-input">
              <label>Enter the Company Name</label>
              <div className="input-group">
                <input
                  type="text"
                  name="companyName"
                  id="companyName"
                  value={companyName}
                  onChange={changeHandler}
                  placeholder="Enter Company Name"
                />
                {error && <div className="text-danger">{error}</div>}
              </div>
            </div>

            <div className="team-member-input">
              <label>Enter Phone Number</label>
              <div className="input-group">
                <input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={phoneNumberChangeHandler}
                  placeholder="Enter Phone Number"
                />
                {phoneNumberError && <div className="text-danger">{phoneNumberError}</div>}
              </div>
            </div>

            <div className="team-member-input">
              <label>Enter Email Address</label>
              <div className="input-group">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={emailChangeHandler}
                  placeholder="Enter Email Address"
                />
                {emailError && <div className="text-danger">{emailError}</div>}
              </div>
            </div>

            <div className="team-member-input checkbox">
              <input
                type="checkbox"
                name="agreement"
                id="agreement"
                checked={isAgreed}
                onChange={checkboxChangeHandler}
              />
              <label>Want Announcement Bar?</label>
            </div>

            {isAgreed && (
              <div className="team-member-input">
                <label>Announcement Bar Content</label>
                <div className="input-group">
                  <input
                    name="content"
                    id="content"
                    value={content}
                    onChange={contentChangeHandler}
                    placeholder="Enter Announcement content here"
                  />
                </div>
                {contentError && <div className="text-danger">{contentError}</div>}
              </div>
            )}

            <div className="team-member-input">
              <div className="team-btn-member button-global-submit ">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  onClick={saveHandler}
                  className="global-icon-btn orange-btn"
                >
                  Add Company
                </button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default AddNewCompany;
