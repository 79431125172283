import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ViewChecklist() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { checklistId, projectId } = useParams();
    const [title, setTitle] = useState('CheckList');
    const [tasks, setTasks] = useState([]);
    const [projectDetails, setProjectDetails] = useState('');
    const [loading, setLoading] = useState(true);
    const [additionalDetails, setAdditionalDetails] = useState([]);
    const [checkedTasks, setCheckedTasks] = useState({});
    const userData = useSelector(state => state.auth.user);

    const fetchData = async () => {
        try {
            const taskResponse = await API.get(`/admin/checklists/${checklistId}/${projectId}/tasks`);
            setTasks(taskResponse.data.tasks);
            setProjectDetails(taskResponse.data.projectDetails);
            
            const additionalResponse = await API.get(`/admin/checklists/${checklistId}/${projectId}/additional-details`);
            setAdditionalDetails(additionalResponse.data);

            const initialCheckedTasks = additionalResponse.data.reduce((acc, detail) => {
                acc[detail.taskId] = detail.status;
                return acc;
            }, {});
            setCheckedTasks(initialCheckedTasks);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Failed to load data');
            setLoading(false);
        }
    };

    useEffect(() => {
        dispatch(pageTitle(title));
        fetchData();
    }, [checklistId, projectId, dispatch, title]);

    const handleCheckboxChange = async (taskId) => {
        const updatedStatus = !checkedTasks[taskId];
        setCheckedTasks((prev) => ({
            ...prev,
            [taskId]: updatedStatus,
        }));

        try {
            const response = await API.post(`/admin/tasks/update-status`, {
                checklistId,
                projectId,
                taskId,
                userId: userData.user.id,
                status: updatedStatus, 
            });

            if (response.data.success) {
                toast.success('Task status updated successfully');
                fetchData();
            } else {
                throw new Error('Failed to update task status');
            }
        } catch (error) {
            console.error('Error updating task status:', error);
            setCheckedTasks((prev) => ({
                ...prev,
                [taskId]: !updatedStatus,
            }));
            toast.error('Failed to update task status');
        }
    };

    const getCompletionDetails = (taskId) => {
        const detail = additionalDetails.find(detail => detail.taskId === taskId);
        return detail ? { completedBy: detail.userDetails.firstName, time: detail.completedAt } : { completedBy: '', time: '' };
    };

    return (
        <div className='main-dashboard-admin'>
            <div className="breadcrumbs">
                <Link to={"/"}>Home </Link>
                <span><i className="fa fa-chevron-right"></i>
                <Link to={"/clients"}> Clients </Link></span>
                <span><i className="fa fa-chevron-right"></i><Link to={`/clients/client-detail/${projectDetails?.company?.id}`}> {projectDetails?.company?.name} </Link></span>
                <span><i className="fa fa-chevron-right"></i><Link to={`/clients/client-detail/project-detail/${projectDetails?.id}`}> {projectDetails?.opportunityName} </Link></span>
                <span ><i className="fa fa-chevron-right"></i><Link className='sr-active-bread' > Checklist</Link></span>
            </div>
            <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
            <div className='teamInfoTable email-table checklist'>
                <table border="0" width="100%">
                    <thead>
                        <tr>
                            <th className="name">Done</th>
                            <th className="projectName">Task</th>
                            <th className="projectName">Completed By</th>
                            <th className="projectName">Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.length > 0 ? (
                            tasks.map((task) => {
                                const { completedBy, time } = getCompletionDetails(task.id);
                                return (
                                    <tr key={task.id}>
                                        <td className="name">
                                            <input
                                                className="custom-checkbox"
                                                type="checkbox"
                                                checked={checkedTasks[task.id] || false} 
                                                onChange={() => handleCheckboxChange(task.id)} 
                                            />
                                        </td>
                                        <td className="projectName">{task.description}</td>
                                        <td className="projectName">{completedBy}</td>
                                        <td className="projectName">
                                            {time ? new Date(time).toLocaleDateString('en-US', { 
                                                month: '2-digit', 
                                                day: '2-digit', 
                                                year: 'numeric' 
                                            }) : ''}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={4}>
                                    <div className="no-data-found no-padding"><span>No tasks found.</span></div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <ToastContainer />
        </div>
    );
}

export default ViewChecklist;
