import React, { useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import { Outlet, useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux';
import API from '../AxiosConfig';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../features/actions/authActions';
import AdminDashboard from '../components/dashboard/AdminDashboard';

export default function Layouts({ isLoggedIn, handleLogin }) {
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState('');
  const reduxAuth = useSelector((state) => state?.auth?.isAuthenticated);
  const userReduxData = useSelector(state=>state.auth.user);
  useEffect(() => {
    if (isLoggedIn && token && !reduxAuth) {
      API.post('/verify-token',{"token":token})
        .then((response) => {
          console.log("Asdasasasd");
          if (response.status === 200) {
            dispatch(loginSuccess(response.data));
            handleLogin(token);
          } else if (response.status === 202 || (response.data.error && response.data.error.name === 'TokenExpiredError')) {
            localStorage.removeItem('token');
            handleLogin(token);
          } else {
            console.log(response.data.message);
            handleLogin(token);
          }
        })
    }
    
  }, [isLoggedIn])
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/", { replace: true });
    } else {
      setCurrentUser({ username: userReduxData?.user?.name, role: userReduxData?.user?.role?.role });
    }
  }, [isLoggedIn,userReduxData]);
  return (
    <div>
      {isLoggedIn && (<Header handleLogin={handleLogin} />)}
      <div className="wrapper-admin">
      {currentUser && isLoggedIn && (<AdminDashboard currentuser={currentUser} />)}
      <Outlet />
      </div>
      <Footer />
    </div>
  )
}
