import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import TimeAgo from './TimeAgo';
import { Tooltip } from 'react-tooltip'
import Modal from 'react-bootstrap/Modal';
import { Bars } from 'react-loader-spinner';

function ArchiveProject() {
    const dispatch = useDispatch();
    const [title, setTitle] = useState(' ');
    const [clientDetail, setClientDetail] = useState({});
    const { id } = useParams();
    const [formData, setFormData] = useState({ id: '', search: '', projectType: '', sortBy: '' });
    const [value, setValue] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [content, setContent] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [stars, setStars] = useState({});
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    let projectId = '';
    const [loading, setLoading] = useState(false);

    const getClientDetail = () => {
        setLoading(true);
        API.get(`/admin/archive-clientDetail/${id}`)
            .then((response) => {
                setStars(response.data.stars);
                setClientDetail(response.data.data.clientDetails);
                setContent(response.data?.data?.clientDetails?.content);
                const clientName = response.data?.data?.clientDetails?.name;
                dispatch(pageTitle(clientName));
                setLoading(false);

                console.log(response.data.data)
            }).catch((e) => {
                console.log(e);
                setLoading(false);

            });
    }

    function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const handleSearch = (e) => {
        const { value } = e.target;
        setValue(value);
    }

    const handleSortChange = (e) => {
        setSortBy(e.target.value);
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };


    const handleContentChange = (event) => {
        setContent(event.target.value);
    };



    const handleClose = () => {
        setShow(false);
    }

    const handleButtonClick = () => {
        API.put('/admin/company-names',
            {
                content: content,
                id: `${id}`,

            })
            .then(response => {
                console.log(response.data);
                getClientDetail();
                setShow(false);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const sortProjects = (filterBySearch) => {
        switch (sortBy) {
            case "Alphabetical":
                return filterBySearch.sort((a, b) =>
                    a.opportunityName.localeCompare(b.opportunityName)
                );
            default:
                return filterBySearch;
        }
    }

    const filterBySearch = clientDetail?.projects?.filter(item => {
        if (!value) {
            return item;
        } else {
            if (item?.opportunityName.toLowerCase().includes(value.toLowerCase())) {
                return item;
            }
        }
    });
    const filteredAndSortedData = sortProjects(filterBySearch);
    const handleStar = (star, projectId) => {
        const token = localStorage.getItem('token');
        API.get(`/admin/updateProjectStar/${star?.id}/${projectId}`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
            .then((response) => {
                console.log(response.data.message);
                getClientDetail()
            }).catch((e) => {
                console.log(e);
            });
    }

    useEffect(() => {
        dispatch(pageTitle(title));
        setFormData({ ...formData, id: id });
        getClientDetail();
    }, []);

    return (

        <div className="main-dashboard-admin">
             {loading &&
            <div className='rotate-line-loader'>
                 <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
            </div>}
            <div class="breadcrumbs">
                <Link to={"/"}>Home</Link><span> / 
                <Link to={"/clients"} > Clients</Link></span><span> / 
                <Link to={`/clients/client-detail/${id}`} className='sr-active-bread' > {clientDetail.name}</Link></span>
            </div>
            <div className='cient-announcement team-header-info'>

                {clientDetail?.content && (
                    <div className='announcement-bar'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <path id="Icon_material-speaker-notes" data-name="Icon material-speaker-notes" d="M17.4,3H4.6A1.6,1.6,0,0,0,3.008,4.6L3,19l3.2-3.2H17.4A1.6,1.6,0,0,0,19,14.2V4.6A1.6,1.6,0,0,0,17.4,3ZM7.8,12.6H6.2V11H7.8Zm0-2.4H6.2V8.6H7.8Zm0-2.4H6.2V6.2H7.8Zm5.6,4.8h-4V11h4Zm2.4-2.4H9.4V8.6h6.4Zm0-2.4H9.4V6.2h6.4Z" transform="translate(-3 -3)" />
                        </svg>
                        <span>
                            {clientDetail.content}
                        </span>
                    </div>
                )}

                <div className='sr-cstm-padding'>
                    <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                    <div className='sr-cstm-combo'>
                        <Link to={`/clients/client-detail/${id}`}>
                            <button className='global-icon-btn orange-btn' >Projects</button>
                        </Link>

                        <Link to={`/clients/archive-client-detail/${id}`}>
                            <button className='global-icon-btn blue-btn' >Archived Projects</button>
                        </Link>
                        <button className='global-icon-btn orange-btn' onClick={() => { setShow(true) }} >Announcement Bar</button>
                        <Link to={`/clients/add-new-Project/${id}`}>
                            <button className='global-icon-btn orange-btn' >Add New Project</button>
                        </Link>
                    </div>
                    <div className='Client-Detail'>
                        <div className='client-wrap-ot'>
                            {/* <div className='row-client-data'>
                                <div className='user-icon'>
                                    <div className='fab-icon'><i className="fas fa-user fa-fw"></i></div>
                                    <p>{clientDetail?.name}</p>
                                </div>
                            </div> */}
                            <div className='row-client-data'>
                                <div className='user-icon'>
                                    <div className='fab-icon'><i className="fas fa-envelope-open fa-fw"></i></div>
                                    <a href={`mailto:${clientDetail?.email}`}>
                                        {Object.keys(clientDetail).length > 0 ? clientDetail?.email : ''}
                                    </a>

                                </div>
                            </div>
                            <div className='row-client-data'>
                            <div className='user-icon'>
                                <div className='fab-icon'><i className="fas fa-phone fa-fw"></i></div>
                                <p>{clientDetail?.phoneNumber}</p>
                            </div>
                        </div>
                        </div>
                        {/* <div className="search-container">
                            <input type="text" placeholder="Search here" name="search" onChange={handleSearch} />
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='member-det-outer'>
                <div className="checklist-header-wrap team-wrap-details">
                    <div className="selectValues">
                        <div className="fields">
                            <label>Sort By:</label>
                            <div className="selectDropdown">
                            <select name="sortby" id="Checklists" onChange={handleSortChange}>
                                <option value=''>Default</option>
                                <option value='Alphabetical'>Alphabetical</option>
                                <option value='Star'>Star</option> 
                            </select>
                                <div className="dropIcon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988">
                                        <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Toastify"></div>
                </div>

                {/* Listing */}
                <div className='member-card-det'>
                    {
                        filteredAndSortedData?.length > 0 ?
                            filteredAndSortedData?.map(project =>
                                <div className="card-det">
                                    <div className='client-info'>
                                        <Link to={`/clients/client-detail/project-detail/${project.id}`}>
                                            <div className='client-pro'>
                                                {/* <img src="/assets/images/cslogo.png"></img> */}
                                                <span>{capitalize(project?.opportunityName)}</span>
                                            </div>
                                        </Link>
                                        <a id={`clickable${project?.id}`}>
                                            <div className='client-review '>
                                                <i className="fas fa-star" style={{ color: project?.star ? project?.star?.name : '' }}></i>
                                            </div>
                                        </a>
                                        <Tooltip anchorSelect={`#clickable${project?.id}`} clickable>
                                            {
                                                stars.length > 0 ?
                                                    stars.map((star) =>
                                                        // <div className='client-review'>
                                                        <button id={`description${star?.id}`} onClick={() => handleStar(star, project?.id)}><i class="fas fa-star" style={{ color: star?.name }}></i></button>
                                                        // </div>
                                                    )
                                                    : ''
                                            }
                                        </Tooltip>
                                    </div>
                                    <div className='client-avater'>
                                        <div className='client-avatar'>
                                            <img src="/assets/images/client-img.png"></img>
                                            <p>
                                                {
                                                    Object.keys(project?.lastestComment).length > 0 ? 'Updated ' : ''
                                                }
                                                {
                                                    Object.keys(project?.lastestComment).length > 0 ?
                                                        <TimeAgo updatedAt={project?.lastestComment.updatedAt} />
                                                        :
                                                        Object.keys(project.latestDiscussion).length > 0 ?
                                                            <TimeAgo updatedAt={project.latestDiscussion.updatedAt} />
                                                            : ''
                                                }
                                                {Object.keys(project?.lastestComment)?.length > 0 ? ' by ' + project?.lastestComment?.user?.firstName : Object.keys(project?.latestDiscussion)?.length > 0 ? ' by ' + project?.latestDiscussion?.addedByUser?.firstName : " No discussion Added"}
                                            </p>
                                        </div>
                                        <div className='client-cmnt'>
                                            <div className='client-box'>
                                                <div className='client-box-icon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.974" height="20.974" viewBox="0 0 20.974 20.974">
                                                        <path id="Icon_material-comment" data-name="Icon material-comment" d="M23.964,5.1A2.1,2.1,0,0,0,21.877,3H5.1A2.1,2.1,0,0,0,3,5.1V17.682a2.1,2.1,0,0,0,2.1,2.1H19.779l4.195,4.195ZM19.779,15.584H7.195v-2.1H19.779Zm0-3.146H7.195v-2.1H19.779Zm0-3.146H7.195v-2.1H19.779Z" transform="translate(-3 -3)" />
                                                    </svg>
                                                </div>
                                                <div className='client-dis'>
                                                    <strong>{project?.discussions?.length} </strong>
                                                    <p>Discussions</p>
                                                </div>
                                            </div>
                                            <div className='client-box'>
                                                <div className='client-box-icon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                        <g id="loader-1-svgrepo-com" transform="translate(-2 -2)">
                                                            <path id="Path_2" data-name="Path 2" d="M12,3V6.833" transform="translate(2.5 0)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                            <path id="Path_3" data-name="Path 3" d="M21.833,12H18" transform="translate(4.167 2.5)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                            <path id="Path_4" data-name="Path 4" d="M12,21.833V18" transform="translate(2.5 4.167)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                            <path id="Path_5" data-name="Path 5" d="M3,12H6.833" transform="translate(0 2.5)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                            <path id="Path_6" data-name="Path 6" d="M5.636,5.636,8.346,8.347" transform="translate(0.732 0.732)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                            <path id="Path_7" data-name="Path 7" d="M18.953,5.636,16.243,8.347" transform="translate(3.678 0.732)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                            <path id="Path_8" data-name="Path 8" d="M18.953,18.953l-2.711-2.711" transform="translate(3.679 3.678)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                            <path id="Path_9" data-name="Path 9" d="M5.636,18.953l2.711-2.711" transform="translate(0.732 3.678)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <div className='client-dis'>
                                                    <strong>0</strong>
                                                    <p>Sprints</p>
                                                </div>
                                            </div>
                                            <div className='client-box'>
                                                <div className='client-box-icon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.974" height="20.443" viewBox="0 0 20.974 20.443">
                                                        <g id="task" transform="translate(-0.3 -0.3)">
                                                            <path id="Path_1" data-name="Path 1" d="M3.574,20.743.3,17.469,1.539,16.23l2.035,2.035L7.38,14.46,8.619,15.7Zm17.7-2.124H10.654v-1.77h10.62Zm-17.7-4.956L.3,10.389,1.539,9.15l2.035,2.035L7.38,7.38,8.619,8.619Zm17.7-2.124H10.654V9.769h10.62ZM3.574,6.583.3,3.309,1.539,2.07,3.574,4.105,7.38.3,8.619,1.539Zm17.7-2.124H10.654V2.689h10.62Z" />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <div className='client-dis'>
                                                    <strong>{project?.taskCount}</strong>
                                                    <p>Tasks</p>
                                                </div>
                                            </div>
                                            <div className='client-box'>
                                                <div className='client-box-icon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.213" height="23.1" viewBox="0 0 20.213 23.1">
                                                        <path id="Icon_metro-files-empty" data-name="Icon metro-files-empty" d="M23.759,9.983a20.451,20.451,0,0,0-1.967-2.249,20.46,20.46,0,0,0-2.249-1.967,3.6,3.6,0,0,0-2.051-.952h-8.3a1.807,1.807,0,0,0-1.8,1.8v16.6a1.807,1.807,0,0,0,1.8,1.8H22.907a1.807,1.807,0,0,0,1.8-1.8V12.034a3.6,3.6,0,0,0-.952-2.051ZM20.772,8.755a19.691,19.691,0,0,1,1.637,1.836H18.936V7.118a19.676,19.676,0,0,1,1.836,1.637Zm2.5,14.469a.366.366,0,0,1-.361.361H9.191a.366.366,0,0,1-.361-.361V6.62a.366.366,0,0,1,.361-.361h8.3v5.053a.722.722,0,0,0,.722.722h5.053ZM16.656,2.88A3.6,3.6,0,0,0,14.6,1.928H6.3a1.807,1.807,0,0,0-1.8,1.8v16.6A1.808,1.808,0,0,0,5.942,22.1V3.733A.366.366,0,0,1,6.3,3.372H17.287c-.218-.179-.429-.344-.631-.492Z" transform="translate(-4.499 -1.928)" />
                                                    </svg>

                                                </div>
                                                <div className='client-dis'>
                                                    <strong> {project?.totalFilesCount}</strong>
                                                    <p>Files</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <Link to={`/clients/client-detail/project-detail/${project.id}`}>Detail</Link> */}
                                </div>
                            )
                            : 'No project Found'
                    }
                </div>
            </div>
            {
                stars.length > 0 ?
                    stars.map((star) =>
                        <Tooltip anchorSelect={`#description${star?.id}`} clickable>
                            <span style={{ color: 'black' }}>{star?.description}</span>
                        </Tooltip>
                    ) : ''
            }

            {/* Announcement Bar Modal */}
            <Modal centered show={show} onHide={handleClose} className='add-announcement-modal'>
                <Modal.Header closeButton>
                    <h4 className="modal-title">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19.2" viewBox="0 0 24 19.2">
                            <path d="M17.6,3H4.4A1.6,1.6,0,0,0,2.8,4.6L2.8,19L6,15.8H17.6A1.6,1.6,0,0,0,19.2,14.2V4.6A1.6,1.6,0,0,0,17.6,3ZM8.8,12.6H7.2V11H8.8Zm0-2.4H7.2V8.6H8.8Zm0-2.4H7.2V6.2H8.8Zm5.6,4.8h-4V11h4Zm2.4-2.4H10.8V8.6h6.4Zm0-2.4H10.8V6.2h6.4Z" transform="translate(-2.8 -3)" />
                        </svg>
                        Edit Announcement
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="calender-box">
                        <div className='sr-announcement'>
                            <div className='sr-announces sr-annouce-2'>
                                <label>Announcement Bar Content</label>
                                <div className="input-group">
                                    <input
                                        name="content"
                                        id="content"
                                        value={content}
                                        onChange={handleContentChange}
                                        placeholder="Enter Announcement content here"
                                    />
                                </div>
                            </div>
                            <div className='button-global-submit sr-announces sr-annouce-3'>
                                <button className='global-icon-btn orange-btn' onClick={handleButtonClick}>Submit</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ArchiveProject