import { useState } from "react";
import { toast } from "react-toastify";
import API from "../../AxiosConfig";
import Modal from "react-bootstrap/Modal";
import MeetingForm from './ActivityLogs/MeetingForm';
import InPersonMeeting from "./ActivityLogs/InPersonMeeting";
import VirtualMeeting from "./ActivityLogs/VirtualMeeting";
import PhoneMeeting from "./ActivityLogs/PhoneMeeting";
import Email from "./ActivityLogs/Email";
import { Link } from "react-router-dom";


function ActivityLogModal({ showLogModal, setShowLogModal, projectid, onClose }) {
  const [companyName, setCompanyName] = useState("");
  const [error, setError] = useState("");


  const handleClose = () => {
    setShowLogModal(false);
	if (onClose) {
		onClose(); 
	  }
  };
  return (
    <>
      <Modal
        centered
        className="modal-opportunity23 fade main-popup-section"
        show={showLogModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <h4 className="modal-title">
		  {/* <svg xmlns="http://www.w3.org/2000/svg" width="16.541" height="24.061" viewBox="0 0 16.541 24.061">
                           <path id="Icon_awesome-lightbulb" data-name="Icon awesome-lightbulb" d="M4.514,21.351a1.5,1.5,0,0,0,.252.831l.8,1.207a1.5,1.5,0,0,0,1.252.671h2.9a1.5,1.5,0,0,0,1.252-.671l.8-1.207a1.5,1.5,0,0,0,.252-.831l0-1.8H4.512l0,1.8ZM0,8.271a8.225,8.225,0,0,0,2.047,5.441,13.5,13.5,0,0,1,2.453,4.3c0,.012,0,.024.005.037h7.53c0-.012,0-.024.005-.037a13.5,13.5,0,0,1,2.453-4.3A8.269,8.269,0,1,0,0,8.271ZM8.271,4.511A3.764,3.764,0,0,0,4.511,8.271a.752.752,0,0,1-1.5,0A5.269,5.269,0,0,1,8.271,3.007a.752.752,0,0,1,0,1.5Z" transform="translate(0 0)"></path>
                        </svg> */}
			Log Opportunity Activity
          </h4>	
		  
        </Modal.Header>
	
		{/* <span className='sr-cstm-activitybutton'>
			<Link to={`/view-activities/${projectid}`}>
				<button className="global-icon-btn orange-btn"> View All Activity	</button>
			</Link>
		</span> */}
        <div className="Company-popup-content">
          <Modal.Body>
          			<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
						<li className="nav-item" role="presentation">
							<button className="nav-link text-primary fw-semibold active position-relative" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-meeting" aria-selected="true">Meeting</button>
						</li>
						<li className="nav-item" role="presentation">
							<button className="nav-link text-primary fw-semibold position-relative" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-person" aria-selected="false">In-Person Meeting</button>
						</li>
						<li className="nav-item" role="presentation">
							<button className="nav-link text-primary fw-semibold position-relative" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-virtual" aria-selected="false">Virtual Meeting</button>
						</li>
						<li className="nav-item" role="presentation">
							<button className="nav-link text-primary fw-semibold position-relative" id="pills-contact1-tab" data-bs-toggle="pill" data-bs-target="#pills-contact1" type="button" role="tab" aria-controls="pills-virtual" aria-selected="false">Phone Call</button>
						</li>
						<li className="nav-item" role="presentation">
							<button className="nav-link text-primary fw-semibold position-relative" id="pills-contact2-tab" data-bs-toggle="pill" data-bs-target="#pills-contact2" type="button" role="tab" aria-controls="pills-virtual" aria-selected="false">Email</button>
						</li>
					</ul>
					  <div className="tab-content border rounded-3 border-primary p-3 text-danger" id="pills-tabContent">
						<div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
							<MeetingForm projectid={projectid} />						   
						</div>
						<div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
							<InPersonMeeting projectid={projectid} />		
						</div>
						<div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
							<VirtualMeeting	projectid={projectid} />							 
						</div>
						<div className="tab-pane fade" id="pills-contact1" role="tabpanel" aria-labelledby="pills-contact-tab1">
						  <PhoneMeeting projectid={projectid} />		
						</div>
						<div className="tab-pane fade" id="pills-contact2" role="tabpanel" aria-labelledby="pills-contact-tab2">
						  <Email projectid={projectid} />
						</div>
					  </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}
export default ActivityLogModal;
