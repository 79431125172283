import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import API from '../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';

function NewProjectType({ show, setShow }) {
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [checklist, setChecklist] = useState('');
  const [errors, setErrors] = useState({});
  const [ProjectTypes, setProjectTypes] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const handleClose = () => {
    setShow(false);
    // Reset fields and errors when closing modal
    setProjectTypes('');
    setChecklist('');
    setErrors({});
  };

  const getProjectTypes = () => {
    API.get('/admin/project-types')
      .then((response) => {
        setProjectTypes(response.data.data);
      }).catch((error) => {
        console.log(error);
      });
  };

  const handleSelectChange = (e) => {
    const newProjectId = e.target.value;
    setSelectedProjectId(newProjectId); // Update selectedProjectId with the new value
    console.log('projectId', newProjectId);
  };

  useEffect(() => {
    if (show) {
      getProjectTypes();
    }
  }, [show]);

  const handleSave = () => {
    // Basic validation
    const errors = {};
    if (!checklist.trim()) {
      errors.checklist = 'Checklist is required';
    }

    // Assuming this code is inside a function or a component
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      API.post(`/admin/projects/${selectedProjectId}/checklists`, { projectId: selectedProjectId, checklist })
        .then(response => {
          if (response.status === 200) {
            setLoading(false);
            toast.success("Created Successfully");
            handleClose();
            
          } else {
            setLoading(false);
            toast.error('Details not saved');
          }
        })
        .catch(error => {
          setLoading(false);
          // Handle error
          console.error('Error saving data:', error);
          setErrors({ axiosError: 'Error saving data' });
        });
    } else {
      // Show errors
      setErrors(errors);
    }
  };

  return (
    <>
      <Modal centered show={show} onHide={handleClose} className='add-checklist-member'>
      {loading &&
            <div className='rotate-line-loader'>
                 <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
            </div>}

        <Modal.Header closeButton>
          <h4 className="modal-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="19.2"
              viewBox="0 0 24 19.2"
            >
              <path
                id="Icon_awesome-user-plus"
                data-name="Icon awesome-user-plus"
                d="M23.4,7.8H21V5.4a.6.6,0,0,0-.6-.6H19.2a.6.6,0,0,0-.6.6V7.8H16.2a.6.6,0,0,0-.6.6V9.6a.6.6,0,0,0,.6.6h2.4v2.4a.6.6,0,0,0,.6.6h1.2a.6.6,0,0,0,.6-.6V10.2h2.4a.6.6,0,0,0,.6-.6V8.4A.6.6,0,0,0,23.4,7.8ZM8.4,9.6A4.8,4.8,0,1,0,3.6,4.8,4.8,4.8,0,0,0,8.4,9.6Zm3.36,1.2h-.626a6.528,6.528,0,0,1-5.467,0H5.04A5.041,5.041,0,0,0,0,15.84V17.4a1.8,1.8,0,0,0,1.8,1.8H15a1.8,1.8,0,0,0,1.8-1.8V15.84A5.041,5.041,0,0,0,11.76,10.8Z"
              />
            </svg>
            Add new checklist
          </h4>
        </Modal.Header>
        <Modal.Body>
        <label htmlFor="projectType">Select the project type</label>
          <div className="form-group selectDropdown">
            <select name="Opportunity" id="types" onChange={handleSelectChange}>
              <option>Select Project Type</option>
              {ProjectTypes && ProjectTypes.map(item =>
                <option key={item.id} value={item.id}>{item.name}</option>
              )}
            </select>
            {errors.projectType && <div className="invalid-feedback">{errors.projectType}</div>}
          </div>
       
          <div className="field">
            <label htmlFor="checklist">Enter the checklist</label>
            <input
              id="checklist"
              placeholder='Enter the checklist'
              className={`form-control ${errors.checklist ? 'is-invalid' : ''}`}
              value={checklist}
              onChange={(e) => setChecklist(e.target.value)}
            />
            {errors.checklist && <div className="invalid-feedback">{errors.checklist}</div>}
          </div>
        </Modal.Body>
          <div className="sr-btn-combo-box form-btn-rows">
          <button type="button" className="global-icon-btn orange-btn" onClick={handleSave}>
            Save
          </button>
          <button type="button" className="global-icon-btn" onClick={handleClose}>
            Close
          </button>
         
          </div>
      </Modal>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
    </>
  );
}

export default NewProjectType;
