import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import API from '../../../AxiosConfig';
import { FaEdit, FaTrash } from 'react-icons/fa';

function CreateColumn({ showBoardColumn, setShowBoardColumn, boardId, onClose }) {
  const [columnName, setColumnName] = useState('');
  const [columnError, setColumnError] = useState('');
  const [columns, setColumns] = useState([]);
  const [editingColumnId, setEditingColumnId] = useState(null);
  const userData = useSelector(state => state.auth.user);

  const fetchColumns = useCallback(async () => {
    try {
      const response = await API.get(`/board/boardColumns/${boardId}`);
      setColumns(response.data);
    } catch (error) {
      console.error('Error fetching columns:', error);
      toast.error('Error fetching columns');
    }
  }, [boardId]);

  useEffect(() => {
    if (showBoardColumn) {
      fetchColumns();
      if (onClose) onClose();
    }
  }, [showBoardColumn, fetchColumns, onClose]);

  const handleColumnNameChange = ({ target: { value } }) => {
    setColumnName(value);
    if (columnError) setColumnError('');
  };

  const handleClose = () => {
    setShowBoardColumn(false);
    setColumnName('');
    setEditingColumnId(null);
  };

  const validateForm = () => {
    if (!columnName.trim()) {
      setColumnError('Column name is required');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      if (editingColumnId) {
        const response = await API.put(`/board/updateColumn/${editingColumnId}`, {
          columnName,
          boardId: userData.boardId,
        });
        setColumns(prev => prev.map(col => col.id === editingColumnId ? response.data : col));
        toast.success('Column updated successfully!');
      } else {
        const response = await API.post(`/board/boardColumns/${boardId}`, {
          columnName,
          boardId: userData.boardId,
        });
        setColumns(prev => [...prev, response.data]);
        toast.success('Column created successfully!');
      }
      handleClose();
    } catch (error) {
      console.error('Error submitting column:', error);
      toast.error('Error submitting column');
    }
  };

  const handleEditClick = (column) => {
    setColumnName(column.columnName);
    setEditingColumnId(column.id);
    setShowBoardColumn(true);
  };

  const handleRemoveClick = async (columnId) => {
    try {
      await API.delete(`/board/deleteColumn/${columnId}`);
      setColumns(prev => prev.filter(col => col.id !== columnId));
      toast.success('Column removed successfully!');
    } catch (error) {
      console.error('Error removing column:', error);
      toast.error('Error removing column');
    }
  };

  return (
    <>
      <Modal centered show={showBoardColumn} onHide={handleClose} className='add-team-modal add-task-module'>
        <Modal.Header closeButton>
          <h4 className="modal-title">{editingColumnId ? 'Edit Column' : 'Add Column'}</h4>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="column-data">
              {columns.length > 0 ? (
                columns.map(column => (
                  <div key={column.id} className="column-title">
                    <div className='column-wraps'>
                      <button
                        type="button"
                        onClick={() => handleEditClick(column)}
                        className="btn-icon"
                        aria-label="Edit"
                      >
                        <FaEdit />
                      </button>
                      <button
                        type="button"
                        onClick={() => handleRemoveClick(column.id)}
                        className="btn-icon"
                        aria-label="Remove"
                      >
                        <FaTrash />
                      </button>
                    <span className="column-name">{column.columnName}</span>
                    </div>
                  </div>
                ))
              ) : (
               ''
              )}
            </div>
            <div className="form-group">
              <label htmlFor="column-name" className="form-label">Column Name</label>
              <input
                type="text"
                id="column-name"
                value={columnName}
                onChange={handleColumnNameChange}
                placeholder="Enter the column name"
                className="form-control"
                required
              />
              {columnError && <span className="error-text">{columnError}</span>}
            </div>
            <div className="form-btn-rows">
              <button type="submit" className="btn global-icon-btn orange-btn">
                {editingColumnId ? 'Update' : 'Submit'}
              </button>
              <button type="button" onClick={handleClose} className="btn global-icon-btn">Cancel</button>
            </div>
          </form>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateColumn;
