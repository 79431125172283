import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import API from '../../../AxiosConfig';

function CreateBoard({ showBoard, setShowBoard, id, onClose }) {
  const [userInvited, setUserInvited] = useState([]);
  const [boardName, setBoardName] = useState('');
  const [boardNameError, setBoardNameError] = useState('');
  const userData = useSelector(state => state.auth.user);

  const handleSelectChange = (selectedOptions) => {
    setUserInvited(selectedOptions);
  };

  const handleBoardNameChange = (event) => {
    setBoardName(event.target.value);
    if (boardNameError) {
      setBoardNameError('');
    }
  };

  const handleClose = () => {
    setShowBoard(false);
    if (onClose) {
      onClose(); 
    }
  };

  const validateForm = () => {
    if (!boardName.trim()) {
      setBoardNameError('Board name is required');
      return false;
    }
    return true;
  };

  const createBoard = async () => {
    // Validate the form first
    if (!validateForm()) {
      return;  // Stop execution if the form is not valid
    }

    try {
      const response = await API.post('/board/create', {
        boardName: boardName,
        projectId: id
      });
      toast.success('Board created successfully');
      handleClose(); 
    } catch (error) {
      console.error('Error creating board:', error);
      toast.error('Error creating board');
    }
  };

  return (
    <>
      <Modal centered show={showBoard} onHide={handleClose} className='add-team-modal'>
        <Modal.Header closeButton>
          <h4 className="modal-title">
            Create Board for this project
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="boardName">Board Name</label>
            <input
              type="text"
              id="boardName"
              value={boardName}
              onChange={handleBoardNameChange}
              className="form-control"
            />
            {boardNameError && <p className="text-danger">{boardNameError}</p>}
          </div>
          <div className="form-btn-rows">
            <button type="button" onClick={createBoard} className="btn global-icon-btn orange-btn">Submit</button>
            <button type="button" onClick={handleClose} className="btn global-icon-btn">Cancel</button>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateBoard;
