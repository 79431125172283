import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';


function SetFollowUpDateModal({ showFollowModal, setShowFollowModal, projectid, onClose }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClose = () => {
    setShowFollowModal(false);
    if (onClose) {
      onClose(); // Call the callback function
    }
  };

  const handleSave = () => {
    if (selectedDate) {
      const formattedDate = `${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}/${selectedDate.getDate().toString().padStart(2, '0')}/${selectedDate.getFullYear()}`;
      saveFollowUpDate(projectid, formattedDate);
    } else {
      toast.error("Please select a date.");
    }
  };

  const saveFollowUpDate = async (projectid, formattedDate) => {
    try {
      const response = await API.post(`/admin/setFollowUpDate`, { opportunityId: projectid, followUpDate: formattedDate });
      if (response.status === 200) {
        toast.success('Date Added Successfully');
        handleClose();
        navigate(`/opportunities/details/${projectid}`);

      } else {
        toast.error('Details not saved');
      }
    } catch (error) {
      toast.error('Error saving data');
      console.error('Error saving data:', error);
    }
  };

  return (
    <Modal centered show={showFollowModal} onHide={handleClose} className='set-followup-modal'>
      <Modal.Header closeButton>
        <h4 className="modal-title">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19.2" viewBox="0 0 24 24">
            <path d="M19,4H18V2.5C18,2.224,17.776,2,17.5,2S17,2.224,17,2.5V4H7V2.5C7,2.224,6.776,2,6.5,2S6,2.224,6,2.5V4H5C3.897,4,3,4.897,3,6v14c0,1.103,0.897,2,2,2h14c1.103,0,2-0.897,2-2V6C21,4.897,20.103,4,19,4z M20,20c0,0.553-0.447,1-1,1H5c-0.553,0-1-0.447-1-1V9h16V20z M20,8H4V6c0-0.553,0.447-1,1-1h1v1.5C6,6.776,6.224,7,6.5,7S7,6.776,7,6.5V5h10v1.5C17,6.776,17.224,7,17.5,7S18,6.776,18,6.5V5h1c0.553,0,1,0.447,1,1V8z" />
          </svg>
          Set Follow-Up Date
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className="calender-left"></div>
        <div className="calender-input">
          <form>
            <label htmlFor="select-date">Select date</label>
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="MM/dd/yyyy"
              placeholderText="mm/dd/yyyy"
              customInput={
                <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                  {(inputProps) => <input {...inputProps} />}
                </InputMask>
              }
            />
              {/* <input type="date" id="select-date"  placeholder="mm/dd/yyyy" name="date" onChange={(e) => handleDateChange(new Date(e.target.value))} /> */}

          </form>
        </div>
        <div className="button-wrapper">
          <button onClick={handleSave} className="primaryBtn">Set Date Now</button>
        </div>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      </Modal.Body>
    </Modal>
  );
}

export default SetFollowUpDateModal;
