import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../features/actions/pageTitleActions';
import { NavLink,Link } from 'react-router-dom';
import API from '../../AxiosConfig';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import csvIcon from '../images/csv_file_logo.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner';
import { Bars } from 'react-loader-spinner';
import { useSelector } from 'react-redux/es/hooks/useSelector';

function AddSop() {
    const [formData, setFormData] = useState({ id : '',title:'',notes : '',projectTypeId: '',file : ''});
    // const [editId,setEditId] = useState({editId:''});
    const [titleName,setTitleName] = useState({titleName:''});
    const [projectTypeId,setprojectTypeId] = useState({projectTypeId:''});
    const navigate  = useNavigate();
    const [errors, setErrors] = useState({});
    const [image, setImage] = useState('');
    const [sopDetail,setSopdetail] = useState({});
    const [projectTypes, setprojectTypes] = useState([]);
    const [loader,setLoader] = useState(false);
    const { id } = useParams();
    const {projectId} = useParams();
    const [loading, setLoading] = useState(false);
    const userData = useSelector(state => state.auth.user);
    const ActiveuserId = userData?.user?.id;

    //get all project types
    const getProjectTypes = () => {
        API.get('/admin/project-types')
        .then((response) => {
          setprojectTypes(response.data.data);
          getDetail();
        }).catch((error) => {
            console.log(error);
        })
    }

    //on change
    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name != 'file')
        {
          setFormData({ ...formData, [name]: value });
          if(name == 'title')
          {
            setTitleName({...titleName,titleName:value});
          }
          else if(name == 'projectTypeId')
          {
            setprojectTypeId({...projectTypeId,projectTypeId:value})
          }
        }
        else if(name == 'file')
        {
          const file = e.target.files[0];
          if (file) {
            //set image into form data
            setFormData({ ...formData, [name]:file });

            //show image into frontend
            if(file.name.split('.').pop() == 'jpg' || file.name.split('.').pop() == 'jpeg' || file.name.split('.').pop() == 'png' || file.name.split('.').pop() == 'svg')
            {
              setImage(URL.createObjectURL(file));
            }
            else{
                checkSetImage(file.name,file)
            }
          }
        }
    };

    function checkSetImage(imageName,data){
      if(imageName.split('.').pop() == 'csv')
      {
        setImage('/assets/images/csv-svgrepo-com.svg');
      }
      else if(imageName.split('.').pop() == 'doc')
      {
        setImage('/assets/images/doc-document-extension-file-file-format-file-type-svgrepo-com.svg');
      }
      else if(imageName.split('.').pop() == 'html')
      {
        setImage('/assets/images/html-5-svgrepo-com.svg');
      }
      else if(imageName.split('.').pop() == 'xml')
      {
        setImage('/assets/images/xml-file-format-variant-svgrepo-com.svg');
      }
      else if(imageName.split('.').pop() == 'zip')
      {
        setImage('/assets/images/compressed-file-zip-svgrepo-com.svg');
      }
      else if(imageName.split('.').pop() == 'pdf')
      {
        setImage('/assets/images/pdf-file-svgrepo-com.svg');
      }
      else if(imageName.split('.').pop() == 'jpg' ||  imageName.split('.').pop() == 'png' || imageName.split('.').pop() == 'svg'){
        setImage(data.file);
      }
      else{
        setImage('/assets/images/file-unknown.svg');
      }
    }

    //on edition
    const getDetail = () => {
      if(id)
      {
        API.get(`/admin/sopDetail/${id}`,{
          }).then((response) => {
            setSopdetail(response.data.data);
            console.log(response.data.data);
            let data = response.data.data;
            // console.log(data);
            let EditableData = {
              // id : data.id,
              id : '',
              // title: data.title,
              title: '',
              notes : data.notes,
              // projectTypeId: data.projectTypeId,
              file:''
            };
            setFormData(EditableData);
            // setEditId({...editId,editId:data.id});
            setTitleName({...titleName,titleName:data.title});
            setprojectTypeId({...projectTypeId,projectTypeId:data.projectTypeId});
            checkSetImage(data.media,data)

          }).catch((err)=>{
            console.log(err);
        });
      }
    }
    
    //on submit
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        let newErrors = {};
        if (formData.projectTypeId === '' && projectTypeId.projectTypeId == '') {
          newErrors = { ...newErrors, projectTypeId: 'Please choose project type' };
        }
        else if(formData.title == '' && titleName.titleName == '')
        {
          newErrors = {...newErrors,title : 'Title is required'}
        }
        else if (formData.notes.trim() === '') {
          newErrors = { ...newErrors, notes: 'Notes are required' };
        }
        else if (formData.file === '' || !formData.file) {
          if(typeof id == 'undefined')
          {
            newErrors = { ...newErrors, file: 'File is required' };
          }
        }
        else if(formData.file !=""){
            const validExtensions = ['jpg', 'jpeg', 'png','csv','doc','html','xml','zip','pdf','mp4'];
            const extension = formData.file.name.split('.').pop().toLowerCase();
            if (!validExtensions.includes(extension)) {
              newErrors = { ...newErrors, file: 'File extension is not allowed' };
            }
        }
        setErrors(newErrors)
        if (Object.keys(newErrors).length === 0) {
          const token = localStorage.getItem('token'); // Get token from local storage
          const data = new FormData();
          Object.keys(formData).map((fields) => {
            data.append(fields, formData[fields]);
          });
          if(typeof id == 'undefined')
          {
            //save
            API.post(`/admin/addSop/${ActiveuserId}`,formData,{
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            }
            })
            .then((response)=>{
              toast.success("SOP Created Successfully");
              const projectTypeId = formData.projectTypeId;
              setTimeout(() => navigate(`/sop/${projectTypeId}`), 1000);
              setFormData(false);
            }).catch((error) => {
              toast.error("Something went wrong");
              console.log(error);
            });
          }
          else{
            let data = formData;
            data.id = id;
            data.title = formData.title ? formData.title : titleName?.titleName;
            data.projectTypeId = formData.projectTypeId?formData.projectTypeId:projectTypeId?.projectTypeId;
            //update data
            API.post('/admin/updateSop',data,{
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            }
            })
            .then((response)=>{
              toast.success("SOP Updated Successfully");
              const projectTypeId = formData.projectTypeId;
              setTimeout(() => navigate(`/sop/${projectTypeId}`), 1000);
              setFormData(false);
            }).catch((error) => {
              setLoader(false);
              toast.error("Something went wrong");
              console.log(error);
            });
          }
        } else {
          setLoader(false);
          setErrors(newErrors);
        }

    }

    const dispatch = useDispatch();
    const title = "Standard Operating Procedures";
      useEffect(() => {
        dispatch(pageTitle(title));
        getProjectTypes();
      },[])

    return (
      <div class="main-dashboard-admin">
      {loader ?
          <div className='rotate-line-loader'>
                <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
          </div>
      :''}
                  <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>

          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="single-standard-op">
                <div className="left-single-standard-op">
                    {image ? <img alt="file" src={image} /> :''}
                    {image.includes('text/csv') ? <img src={csvIcon} alt="CSV file" />: ''}
                </div>
                <div className="right-single-standard-op">
                  <div className='select-box-sop selectDropdown'>
                    <label>Select SOP Type</label>
                  <select name="projectTypeId" id="" className="form-select" aria-label="Default select example" onChange={handleChange}>
                    <option value="">Choose Project Type</option>
                    {
                      projectTypes.map(item =>
                        <option value={item.id} selected={projectTypeId?.projectTypeId == item.id}>{item.name}</option>
                      )
                    }
                  </select>
                  <span className='text-danger'>{errors?.projectTypeId}</span>
                  </div>

                  <div className="row">
                  <label>Title</label>
                    <div className="col-md-12">
                    {/* formData.title?formData.title: */}
                      <input type="text" name="title" placeholder='Title' onChange={handleChange} value={titleName?.titleName} className='form-control'/>
                    </div>
                    <span className='text-danger'>{errors?.title}</span>
                  </div>
                    <div className="editor-right-standard-op mt-2">
                      <label>Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data = {formData?formData.notes:''}
                        onChange={(event,editor)=>{
                          setFormData({...formData,notes:editor.getData()});
                        }}
                      />
                      <span className='text-danger'>{errors?.notes}</span>
                    </div>
                    <div className='mt-2'>
                    <input type="file" name="file" onChange={handleChange}/> 
                    </div>
                    <span className='text-danger'>{errors?.file}</span>
                    <div className="buttons-editor">
                        <button href="javascript:void(0)" className="global-icon-btn orange-btn" type="submit">
                            <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>
                            { Object.keys(sopDetail).length !== 0 ? 'Update' : 'Save' }
                        </button>
                        <Link to={`/sop/${projectId}`} className="global-icon-btn">
                            <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                            Cancel
                        </Link>
                    </div>
                </div>
            </div>
          </form>
          <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark"/>
        </div>
    )
}
export default AddSop
