import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import API from '../AxiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { pageTitle } from '../features/actions/pageTitleActions';
import { ToastContainer, toast } from 'react-toastify';
import { updateProfile } from '../features/actions/updateProfileAction';

function MyProfile() {
    const [profile, setProfile] = useState({
        firstName: '',
        lastName: '',
        email: ''
    });

    const [passwords, setPasswords] = useState({
        newPassword: '',
        confirmPassword: ''
    });

    const [passwordError, setPasswordError] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [errors, setErrors] = useState({});
    
    const dispatch = useDispatch();
    const title = "My Profile";

    const userData = useSelector(state => state.auth.user);
    console.log(userData);

    useEffect(() => {
        dispatch(pageTitle(title));

        if (userData) {
            setProfile({
                firstName: userData.user.firstName || '',
                lastName: userData.user.lastName || '',
                email: userData.user.email || ''
            });
        }
    }, [userData, dispatch, title]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfile({
            ...profile,
            [name]: value
        });
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswords({
            ...passwords,
            [name]: value
        });
    };

    const [files, setFile] = useState({});
    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setFile(file);
            setImagePreview(reader.result); 
          };
          reader.readAsDataURL(file);
        }
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('firstName', profile.firstName);
        formData.append('lastName', profile.lastName);
        if (files) {
            formData.append('media', files); // Append the file
        }
    
        try {
            const response = await API.post(`/admin/myProfile/${userData.user.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Set the content type to multipart/form-data
                }
            });
    
            if (response.status === 200) {
                console.log(response.data);
                // dispatch(updateProfile(response.data));
                toast.success("Profile updated successfully");
            } else {
                // handle error response
                alert('Failed to update profile');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            alert('An error occurred while updating the profile');
        }
    };
    

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();

        if (passwords.newPassword !== passwords.confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        }

        if (passwords.newPassword.length < 6) {
            setPasswordError('Password should be at least 6 characters long');
            return;
        }

        try {
            const response = await API.post(`/admin/changePassword/${userData.user.id}`, {
                password: passwords.newPassword
            });
            if (response.status === 200) {
                toast.success("Password changed successfully");
                setPasswords({
                    newPassword: '',
                    confirmPassword: ''
                });
                setPasswordError('');
                // dispatch({
                //     type: 'UPDATE_USER_PROFILE',  
                //     payload: {
                //         ...userData.user,
                //         firstName: profile.firstName,
                //         lastName: profile.lastName,
                //     },
                // });
            } else {
                alert('Failed to change password');
            }
        } catch (error) {
            console.error('Error changing password:', error);
            alert('An error occurred while changing the password');
        }
    };

    const togglePasswordVisibility = (e) => {
        const passwordInput = e.target.previousSibling;
        if (passwordInput.type === 'password') {
            passwordInput.type = 'text';
        } else {
            passwordInput.type = 'password';
        }
    };

    return (
        <div className="main-dashboard-admin">
            <Link to={`/`} >
                <button className='btn btn-primary back-to-previous mb-2'>
                    <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                </button>
            </Link>

            <div className='profile-text'>
                <h2>Settings</h2>
                <div className='profile-btn'>
                    <Link to={`/managePermissions`}>
                        <button className='global-icon-btn orange-btn'>Manage Permissions</button>
                    </Link>
                </div>
            </div>
            <div className='profile-grid'>
                <div className='profile-left'>
                    <h4>Contact Information</h4>
                    <form onSubmit={handleSubmit}>
                        <div className="file file--upload">
                            <label htmlFor="input-file">
                                <div className='user-icon-up'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="68.854" height="56.881" viewBox="0 0 68.854 56.881">
                                        <g id="Icon_feather-user-plus" data-name="Icon feather-user-plus" transform="translate(0 -3)">
                                            <path id="Path_7" data-name="Path 7" d="M46.4,40.46V34.474A11.974,11.974,0,0,0,34.427,22.5H13.474A11.974,11.974,0,0,0,1.5,34.474V40.46" transform="translate(0 17.921)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"></path>
                                            <path id="Path_8" data-name="Path 8" d="M30.7,16.474A11.974,11.974,0,1,1,18.724,4.5,11.974,11.974,0,0,1,30.7,16.474Z" transform="translate(5.227)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"></path>
                                            <path id="Path_9" data-name="Path 9" d="M30,12V29.96" transform="translate(28.374 7.467)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"></path>
                                            <path id="Path_10" data-name="Path 10" d="M43.46,16.5H25.5" transform="translate(23.894 11.947)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"></path>
                                        </g>
                                    </svg>
                                </div>
                                <span>Upload Profile Images</span>
                            </label>
                            {/* <input
                                type="file"
                                accept="image/*"
                                className="form-control"
                                id="input-file"
                                name="media"
                                onChange={handlePhotoChange}
                            /> */}
                            <input type="file" name="media" id="input-file" onChange={handlePhotoChange} />

                        </div>
                        {imagePreview && (
                            <div className="image-preview">
                                <img src={imagePreview} alt="Preview" style={{ maxWidth: '250px', height: 'auto' }} />
                            </div>
                        )}
                        {errors.photo && <p style={{ color: 'red' }}>{errors?.photo}</p>}
                        <div className='row-form'>
                            <div className='form-group'>
                                <label>First Name</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    required
                                    className="form-control"
                                    value={profile.firstName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='form-group'>
                                <label>Last Name</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    className="form-control"
                                    value={profile.lastName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='form-group'>
                                <label>Email Address</label>
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    className="form-control"
                                    value={profile.email}
                                    onChange={handleInputChange}
                                    disabled
                                />
                            </div>
                            <div className="button-global-submit">
                                <button className="global-icon-btn orange-btn" type="submit">Save</button>
                                {/* <a className="global-icon-btn" href="/opportunities">Cancel</a> */}
                            </div>
                        </div>
                    </form>
                </div>
                <div className='profile-right'>
                    <h4>Change Password</h4>
                    <form onSubmit={handlePasswordSubmit}>
                        <div className='row-form'>
                            <div className='form-group'>
                                <label>New Password</label>
                                <div className="icon-filed">
                                    <input
                                        type="password"
                                        id="newPassword"
                                        name="newPassword"
                                        className="form-control"
                                        value={passwords.newPassword}
                                        onChange={handlePasswordChange}
                                    />
                                    <i className="toggle-password fa fa-eye" onClick={togglePasswordVisibility}></i>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label>Confirm Password</label>
                                <div className="icon-filed">
                                    <input
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        className="form-control"
                                        value={passwords.confirmPassword}
                                        onChange={handlePasswordChange}
                                    />
                                    <i className="toggle-password fa fa-eye" onClick={togglePasswordVisibility}></i>
                                </div>
                            </div>
                            {passwordError && <div className="error-message">{passwordError}</div>}
                            <div className="button-global-submit">
                                <button className="global-icon-btn orange-btn" type="submit">Save</button>
                                {/* <a className="global-icon-btn" href="/opportunities">Cancel</a> */}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
}

export default MyProfile;

