import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import API from '../../AxiosConfig';
import { loginSuccess, loginFailure } from '../../features/actions/authActions';

const UserLogin = ({ credentials, handleLogin,setError }) => {
  const dispatch = useDispatch();


  useEffect(() => {
    const userLoginApi = async (credentials) => {
      await API.post('/login', credentials)
        .then((response) => {
          if (response.status === 200) {
            // console.log('Login',response);
            const { token } = response.data;
            localStorage.setItem('token', token); 
            dispatch(loginSuccess(response.data));
            handleLogin(token);
          } else if(response.status === 201){
            setError(response.data.error);
          }
          else {
            setError('Error while Logging In');
          }
        })
        .catch((error)=>{
          setError('Error while Logging In');
          console.log(error);
        })
      // console.log(res);
    }
    userLoginApi(credentials);

  }, [credentials,handleLogin,dispatch]);

};

export default UserLogin;