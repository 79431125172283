import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import API from '../AxiosConfig';
import { Link } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

function ManagePermissions() {
    const [permissions, setPermissions] = useState({
        admin: { add: false, modify: false, delete: false, read: false },
        client: { add: false, modify: false, delete: false, read: false },
        employee: { add: false, modify: false, delete: false, read: false },
        manager: { add: false, modify: false, delete: false, read: false },
    });

    const fetchPermissions = async () => {
        try {
            const response = await API.get('/admin/permissions');
            const data = response.data;

            const updatedPermissions = data.reduce((acc, permission) => {
                acc[permission.role] = {
                    add: permission.add,
                    modify: permission.modify,
                    delete: permission.delete,
                    read: permission.read,
                };
                return acc;
            }, {});

            setPermissions(updatedPermissions);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to fetch permissions');
        }
    };

    const handleChange = (role, permission) => async (e) => {
        const updatedPermissions = {
            ...permissions,
            [role]: {
                ...permissions[role],
                [permission]: e.target.checked,
            },
        };

        setPermissions(updatedPermissions);

        try {
            const response = await API.post('/admin/permissions', JSON.stringify(updatedPermissions), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            toast.success("Permissions updated successfully", {
                position: "top-center"
            });
        } catch (error) {
            toast.error(error.response?.data?.message || 'An error occurred.');
        }
    };

    useEffect(() => {
        fetchPermissions();
    }, []);

    return (
        <div className="main-dashboard-admin">
             <Link to={`/`} >
            <button className='btn btn-primary back-to-previous mb-2' ><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
            </Link>
                <div className='profile-text'>
                <h2>Permissions</h2>
                <div className='profile-btn'>
                    <Link to={`/myProfile`}>
                        <button className='global-icon-btn orange-btn'>Settings</button>
                    </Link>
                </div>
            </div>
            <div className="teamInfoTable sr-cstm-permissiontable">
                <table border="0" width="100%">
                    <thead>
                        <tr>
                            <th className="name">Permissions</th>
                            <th className="email">Add</th>
                            <th className="addedOn">Modify</th>
                            <th className="status">Delete</th>
                            <th className="status">Read</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(permissions).map((role) => (
                            <tr key={role}>
                                <td className="name">{role.charAt(0).toUpperCase() + role.slice(1)}</td>
                                <td className="email">
                                    <input
                                        type="checkbox"
                                        name={`${role}_add`}
                                        checked={permissions[role].add}
                                        onChange={handleChange(role, 'add')}
                                    />
                                </td>
                                <td className="addedOn">
                                    <input
                                        type="checkbox"
                                        name={`${role}_modify`}
                                        checked={permissions[role].modify}
                                        onChange={handleChange(role, 'modify')}
                                    />
                                </td>
                                <td className="noOfProject">
                                    <input
                                        type="checkbox"
                                        name={`${role}_delete`}
                                        checked={permissions[role].delete}
                                        onChange={handleChange(role, 'delete')}
                                    />
                                </td>
                                <td className="status">
                                    <input
                                        type="checkbox"
                                        name={`${role}_read`}
                                        checked={permissions[role].read}
                                        onChange={handleChange(role, 'read')}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <ToastContainer />
        </div>
    );
}

export default ManagePermissions;
