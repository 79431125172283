import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API from '../../AxiosConfig';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import DeleteModal from './Model/taskDeleteModal';


function TaskDetailPage() {
    const navigate = useNavigate();
    const [taskDetails, setTaskDetails] = useState(null);
    const { taskId } = useParams();
    const [totalHours, setTotalHours] = useState(0);
    const [totalBillableHours, setTotalBillableHours] = useState(0);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedProjectId, setSelectedProjectId] = useState(null); 
    const [projectsUsers, setProjectsUsers] = useState(); 
    const [isEditing, setIsEditing] = useState(false);
    const [editedTaskDetails, setEditedTaskDetails] = useState(taskDetails);
    const userData = useSelector(state => state.auth.user);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [totalNonBillableHours, setTotalNonBillableHours] = useState(0);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const [selectedUser, setSelectedUser] = useState('');
    const handleEditClick = () => {
        setIsEditing(true);
        setEditedTaskDetails(taskDetails); 
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditedTaskDetails(taskDetails); 
    };

    const handleSaveClick = async () => {
        try {
            const { taskTitle, taskDescription } = editedTaskDetails;
            const response = await API.put(`/board/tasks/${editedTaskDetails.id}`, {
                taskTitle,
                taskDescription
            });
    
            if (response.status === 200) {
                setTaskDetails((prevDetails) => ({
                    ...prevDetails,
                    taskTitle: response.data.taskTitle,
                    taskDescription: response.data.taskDescription
                }));
                setIsEditing(false);
            } else {
                console.error("Failed to save task details");
            }
        } catch (error) {
            console.error("Error saving task details:", error);
        }
    };
    
    

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setEditedTaskDetails({ ...editedTaskDetails, [name]: value });
    };

    const handleChange = async (e) => {
        const selectedUserId = e.target.value;
        setSelectedUser(selectedUserId);
    
        try {
            const response = await API.post('/board/assignUserToTask', {
              taskId: taskId,
              assignedUserId: selectedUserId
            }, {
              headers: {
                'Content-Type': 'application/json'
              }
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            console.log('Task updated successfully:', result);
        } catch (error) {
            console.error('Error updating task:', error);
        }
    };


    useEffect(() => {
        if (taskDetails?.trackRecords) {
            let total = 0;
            let billableTotal = 0;
            let nonBillableTotal = 0;
            taskDetails.trackRecords.forEach(record => {
                const billable = parseFloat(record.billableHours) || 0;
                const nonBillable = parseFloat(record.nonBillableHours) || 0;
                total += billable + nonBillable;
                billableTotal += billable;
                nonBillableTotal += nonBillable;
            });
            setTotalHours(total);
            setTotalBillableHours(billableTotal);
            setTotalNonBillableHours(nonBillableTotal); 
        }
    }, [taskDetails]);
    
    const fetchTaskDetails = () => {
        const token = localStorage.getItem('token');
        API.get(`/board/project-task-detail/${taskId}`,{
            headers: {
                'authorization': token,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response.data);
            setTaskDetails(response.data.projectDetail);
            setSelectedProjectId(response.data.project.id);
            setProjectsUsers(response.data.invitedUsers);
            }).catch((e) => {
                console.log(e);
            });
    }

    useEffect(() => {
        fetchTaskDetails();
    }, []);

    const handleSave = async () => {
        const billableHours = document.querySelector('input[name="billableHours"]').value;
        const nonBillableHours = document.querySelector('input[name="nonBillableHours"]').value;
        const description = document.querySelector('textarea[name="description"]').value;
        try {
            const response = await API.post(`/timeTracking/timeTracking/${selectedProjectId}`, {
                projectId: selectedProjectId,
                date: selectedDate,
                billableHours,
                nonBillableHours,
                description,
                taskId: taskId,
                userID : userData.user.id
            });
            if (response.data.success) {
                toast.success('Data saved successfully', {
                    position: toast.POSITION.TOP_CENTER,
                });
                fetchTaskDetails();
                setSelectedProjectId(null);
                setSelectedDate(null);
                document.querySelector('input[name="billableHours"]').value = '';
                document.querySelector('input[name="nonBillableHours"]').value = '';
                document.querySelector('textarea[name="description"]').value = '';
            } else {
                toast.error('Error while saving data', {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } catch (error) {
            console.error(error);
            toast.error('Error while saving data', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };
    

    const handlearchive = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await API.post(`/board/archieve-task/${taskId}`, {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.success) {
                toast.success('Task Archived successfully', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                navigate(-1);
            } else {
                toast.error('Error while making task archived', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            console.error('Error deleting task:', error);
            toast.error('Error while deleting task', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    const handleShowModalDelete = () => {
        setShowDeleteModal(true);
      };

   

    return (
        <div className="main-dashboard-admin task-details-inner">
                <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}>
                <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
            </button>
            <div className='btnd-wrapps'>
                <button className='btn btn-danger mb-2' onClick={handleShowModalDelete} >
                    <i className="fa fa-trash" aria-hidden="true"></i>Delete Task
                </button>
                <button className='btn btn-danger mb-2' onClick={handlearchive}>
                <i class="fa fa-archive" aria-hidden="true"></i>
                Archive Task
                </button>
                {isEditing ? (
                            <div className='btns-tasks-det'>
                                <button className="btn btn-secondary sr-cstm-btn" onClick={handleCancelClick}>Cancel</button>
                                <button className="btn btn-primary sr-cstm-btn save-btn" onClick={handleSaveClick}>Save</button>
                            </div>
                        ) : (
                            <button className='btn btn-primary sr-cstm-btn' onClick={handleEditClick}><i className="fa fa-edit" aria-hidden="true"></i>Edit</button>
                        )}
                </div>
            <div className='task-det-main-outer'>
                <div className='task-det-left-side'>
                    <div className='task-design'>
                        <div className='task-design-heading'>
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                            {isEditing ? (
                                <>
                                <span>Title</span>
                                <input
                                    type="text"
                                    name="taskTitle"
                                    value={editedTaskDetails?.taskTitle || ''}
                                    onChange={handleFieldChange}
                                />
                                </>
                            ) : (
                                <h4>Task {taskDetails?.id} - {taskDetails?.taskTitle}</h4>
                            )}
                        </div>
                        <div className="task-design-cont">
                            <span>Description</span>
                            {isEditing ? (
                                <textarea
                                    name="taskDescription"
                                    value={editedTaskDetails?.taskDescription || ''}
                                    onChange={handleFieldChange}
                                />
                            ) : (
                                <p>{taskDetails?.taskDescription}</p>
                            )}
                        </div>
                        <div className='task-comment'>
                            <h2>Comments</h2>
                            {taskDetails?.comments?.map((comment, index) => (
                                <div className='task-client' key={index}>
                                    <div className='client-img'>
                                        <img src={comment.clientImgUrl} alt="client"></img>
                                    </div>
                                    <div className='task-client-data'>
                                        <h4>{comment.clientName}</h4>
                                        <p>{comment.commentText}</p>
                                        <span>Posted on {comment.postedDate}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                </div>
                <div className='task-det-right-side'>
                    <div className="selectValues">
                        <div className="fields">
                            <label htmlFor="types">Status</label>
                            <div className="selectDropdown">
                                <select>
                                    <option value="">All</option>
                                    <option value="Pre Sales">Pre Sales</option>
                                    <option value="Project Open">Project Open</option>
                                    <option value="Project Awarded">Project Awarded</option>
                                    <option value="Project Closed">Project Closed</option>
                                </select>
                                <div className="dropIcon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988"><path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path></svg>
                                </div>
                            </div>  
                        </div>
                        <div className="fields">
                            <label htmlFor="sortBy">Assigned to </label>
                            <div className="selectDropdown">
                            <select id="userDropdown" value={selectedUser || taskDetails?.taskAssignedTo} onChange={handleChange}>
                                <option value="">None</option>
                                {projectsUsers?.map(user => (
                                    <option key={user.id} value={user.id}>
                                        {user.fullName || `${user.firstName} ${user.lastName}`}
                                    </option>
                                ))}
                            </select>

                                <div className="dropIcon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988"><path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path></svg>
                                </div>
                            </div>
                        </div>
                        <div className="fields">
                            <label htmlFor="sortBy">Created By</label>
                            <div className="selectDropdown">
                            <input type="text" id="createdBy" name="createdBy" value={taskDetails?.taskCreatedBy} disabled/>
                            </div>
                        </div>
                        {/* <div className="fields">
                            <label htmlFor="sortBy">Sprint</label>
                            <div className="selectDropdown">
                                <select>
                                    <option value="Alphabetical">Alphabetical</option>
                                    <option value="Amount">Amount</option>
                                </select>
                                <div className="dropIcon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988"><path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path></svg>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className='hours-spent'>
                        <h2>Hours Spent</h2>
                        {taskDetails?.trackRecords?.map((hour, index) => (
                            <div className='hours-spent-inner' key={index}>
                                <div className='hours-format'>
                                    <svg></svg>
                                    <span>{new Date(hour.date).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit'
                                        })}</span>
                                    </div>
                                <div className='hours-format'>
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z" fill="#878787"></path> <path d="M12 5C11.4477 5 11 5.44771 11 6V12.4667C11 12.4667 11 12.7274 11.1267 12.9235C11.2115 13.0898 11.3437 13.2343 11.5174 13.3346L16.1372 16.0019C16.6155 16.278 17.2271 16.1141 17.5032 15.6358C17.7793 15.1575 17.6155 14.5459 17.1372 14.2698L13 11.8812V6C13 5.44772 12.5523 5 12 5Z" fill="#878787"></path> </g></svg>
                                <span>{hour.billableHours ? `${hour.billableHours} Hours` : "0 Hours"}</span>
                                </div>
                                <div className='hours-format'>
                                    <svg></svg>
                                    <span>{hour.user.firstName}</span>
                                </div>
                                <div className='hours-format'>
                                    <svg></svg>
                                    <span>{hour.description}</span>
                                </div>
                            </div>
                        ))}
                        <div className='total-spend'>
                            <p>Total: {totalHours} hours</p>
                            <span>(Billable - {totalBillableHours} Hours)</span>
                            <span>(Non-Billable - {totalNonBillableHours} Hours)</span>
                        </div>
                    </div>
                    <div className='add-hours'>
                        <div className='add-hour-heading'>
                            <h2>Add Hours</h2>
                            <div className='line'></div>
                            </div>
                        <div className='slect-task comn-vk'>
                            <label>Select Date</label>
                            <DatePicker
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="mm/dd/yyyy"
                                        customInput={
                                            <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                                              {(inputProps) => <input {...inputProps} />}
                                            </InputMask>
                                          }
                                    />
                        </div>
                        <div className="slect-task comn-vk">
                            <label>Billable Hours</label>
                            <input
                                type="number"
                                id="quantity"
                                min="0"
                                max="24"
                                name="billableHours"
                                onInput={(e) => {
                                    e.target.value = Math.max(0, Math.min(24, parseInt(e.target.value) || 0));
                                    if (e.target.value.length > 2) {
                                        e.target.value = e.target.value.slice(0, 2);
                                    }
                                }}
                            />
                        </div>
                        <div className="slect-task comn-vk">
                            <label>Non-Billable Hours</label>
                            <input
                                type="number"
                                id="quantity"
                                min="0"
                                max="24"
                                name="nonBillableHours"
                                onInput={(e) => {
                                    e.target.value = Math.max(0, Math.min(24, parseInt(e.target.value) || 0));
                                    if (e.target.value.length > 2) {
                                        e.target.value = e.target.value.slice(0, 2);
                                    }
                                }}
                            />
                        </div>


                        <div className='description comn-vk'>
                            <label>Description</label>
                            <textarea id="w3review" name="description" rows="4" cols="50"></textarea>
                        </div>
                        <div className='billable-check comn-vk'>
                            <label></label>
                            <div className="button-global-submit">
                            <a className="global-icon-btn"  onClick={handleSave} href="javascript:void(0)">Add</a>
                            </div>
                        </div>
                    </div>
                </div>
                <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal}  taskId={taskId} />
            </div>
        </div>
    );
}

export default TaskDetailPage;
