import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../features/actions/pageTitleActions';
import { Link } from 'react-router-dom';
import { FileUploader } from "react-drag-drop-files";
import API from '../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddNewCompany from './AddNewCompanyModel';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from 'react-router-dom';
import FileUpload from '../FileUpload/FileUpload';
import AddTeamMember from './AddTeamMember';
import { Bars } from 'react-loader-spinner';
import Select from 'react-select';

function AddOpportunity() {
    const [formData, setFormData] = useState({ companyName: '', companyId: '', opportunityName: '', opportunityStatus: '', opportunityAmount: '', notes:'' });
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [users, setTeamMembers] = useState([]);
    const [editorData, setEditorData] = useState('');
    const [files, setFile] = useState({});
    const [companyOptions, setCompanyOptions] = useState([]);
    const [show, setShow] = useState(false);
    const [showTeam, setShowTeam] = useState(false);
    const dispatch = useDispatch();
    const navigate  = useNavigate();
    const title = "Add New Opportunity";
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        companyName: '',
        opportunityName: '',
        oppname: '',
        opportunityStatus: '',
        opportunityAmount: '',
        content: '',
    });

    const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
    console.log('selectedTeamMembers', selectedTeamMembers);

    const handleSelectTeamMembers = (selectedMembers) => {
      setSelectedTeamMembers(selectedMembers);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCompanyChange = (selectedOption) => {
        setFormData({
            ...formData,
            companyName:  selectedOption.value,
        });
    };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedCheckboxes([...selectedCheckboxes, value]);
        } else {
            setSelectedCheckboxes(selectedCheckboxes.filter(item => item !== value));
        }
    };

    const handleChangefile = (file) => {
        setFile(file);
    };

    const handleEditorChange = (notes) => {
        setFormData({ ...formData, notes });
    };

    const handleKeyPress = (event) => {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let newErrors = {};

        if (!formData.companyName) {
            newErrors.companyName = "Opportunity Company Name is Required";
        }
        if (!formData.opportunityName.trim()) {
            newErrors.opportunityName = 'Opportunity Name is required';
        }
        if (!formData.opportunityStatus.trim()) {
            newErrors.opportunityStatus = 'Please Select Opportunity Status';
        }
        if (!formData.opportunityAmount.trim()) {
            newErrors.opportunityAmount = 'Please Select Opportunity Amount';
        }
        if (selectedCheckboxes.length === 0) {
            newErrors.servicesInterested = "Please Select at least one option";
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            const data = new FormData();
            const token = localStorage.getItem('token');
            Object.keys(formData).forEach((field) => {
                data.append(field, formData[field]);
            });
            selectedCheckboxes.forEach((service) => {
                data.append('servicesInterested', service);
            });
            Object.keys(files).forEach((key) => {
                if (key !== 'length') data.append("media", files[key]);
            });
            data.append('userInvited', JSON.stringify(selectedTeamMembers));

            setLoading(true);
            API.post('/admin/oppurtunity', data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    toast("Opportunity Created");
                    setFormData({ companyName: '', companyId: '', opportunityName: '', opportunityStatus: '', opportunityAmount: '', notes:'' });
                    setTimeout(() => navigate('/opportunities'), 2000);
                } else {
                    setLoading(false);
                    toast.error('Opportunity not saved', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error('An error occurred while saving the opportunity');
            });
        }
    };

    const getClientsList = async () => {
        try {
            setLoading(true);
            const response = await API.get('/admin/company-names');
            const companies = response.data.companyNames.map((company) => ({
                value: company.id,
                label: company.name
            }));
            setCompanyOptions(companies);
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    const refreshState = () => {
        getClientsList();
      };

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const response = await API.get('/admin/addTeam');
            setTeamMembers(response.data.data);
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        dispatch(pageTitle(title));
        getClientsList();
        fetchUsers();
    }, []);

    const handleClose = () => {
        setShowTeam(false);
    };

    return (
        <div className="main-dashboard-admin">
             {loading &&
            <div className='rotate-line-loader'>
                 <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
            </div>}
            <form onSubmit={handleSubmit}>
                <div className="addNewOppFields">
                    <div className="fieldsBlock">
                    <div className="field">
                            <label htmlFor="">Opportunity Company Name<span style={{ color: 'red' }}>*</span></label>
                            <div className="selectDropdown">
                                <Select
                                    options={companyOptions}
                                    onChange={handleCompanyChange}
                                    placeholder=""
                                />
                            </div>
                            <span className='add-new-company' onClick={() => setShow(true)}><i className="fa fa-plus" aria-hidden="true"></i> Add New Company</span>
                            {errors.companyName && <span className='error-form-field' style={{ color: 'red' }}>{errors.companyName}</span>}
                        </div>

                        <div className="field opportunitiesName ">
                            <label htmlFor="">Opportunity Name<span className='' style={{ color: 'red' }}>*</span></label>
                            <input type="text" placeholder="" name='opportunityName' onChange={handleChange} maxLength={32} />
                            {errors.opportunityName && <span style={{ color: 'red' }}>{errors.opportunityName}</span>}
                        </div>
                        {/* <div className="field ">
                            <label htmlFor="">Opportunity ID<span className='' style={{ color: 'red' }}>*</span></label>
                            <input type="number" placeholder="" name='opportunityID' onChange={handleChange} maxLength={32} />
                            {errors.opportunityID && <span style={{ color: 'red' }}>{errors.opportunityID}</span>}
                        </div> */}
                        {/* <div className="field ">
                            <label htmlFor="">Opportunity Contact Name<span className='' style={{ color: 'red' }}>*</span></label>
                            <input type="text" placeholder="" name='contactName' onChange={handleChange} maxLength={32} />
                            {errors.contactName && <span style={{ color: 'red' }}>{errors.contactName}</span>}
                        </div>
                        <div className="field ">
                            <label htmlFor="">Opportunity Phone Number<span className='' style={{ color: 'red' }}>*</span></label>
                            <input type="text" placeholder="" name='phoneNumber' onChange={handleChange} maxLength={14} onKeyPress={handleKeyPress}/>
                            {errors.phoneNumber && <span style={{ color: 'red' }}>{errors.phoneNumber}</span>}

                        </div> */}
                        {/* <div className="field ">
                            <label htmlFor="">Opportunity Email<span className='' style={{ color: 'red' }}>*</span></label>
                            <input type="text" placeholder="" name='email' onChange={handleChange} />
                            {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
                        </div> */}
                        <div className="field ">
                            <label htmlFor="">Opportunity Status<span className='' style={{ color: 'red' }}>*</span></label>
                            <div className="selectDropdown">
                                <select name="opportunityStatus" id="types" onChange={handleChange}>
                                    <option value="">Please Select</option>
                                    <option value="Initial Discussions">Initial Discussions</option>
                                    <option value="Awaiting Proposal">Awaiting Proposal</option>
                                    <option value="Proposal Sent">Proposal Sent</option>
                                    <option value="Project Lost">Project Lost</option>
                                    <option value="Project Won">Project Won</option>
                                </select>
                                <div className="dropIcon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988"
                                        viewBox="0 0 13.971 7.988">
                                        <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward"
                                            d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z"
                                            transform="translate(20.168 -11.246) rotate(90)" />
                                    </svg>
                                </div>
                            </div>
                            {errors.opportunityStatus && <span style={{ color: 'red' }}>{errors.opportunityStatus}</span>}
                        </div>
                        <div className="field ">
                            <label htmlFor="">Opportunity Amount<span className='' style={{ color: 'red' }}>*</span></label>
                            <div className='dollarWrap'>
                            <div className='dollarSign'>$</div>
                            <input type="number" placeholder="" min='0' name='opportunityAmount' onChange={handleChange} onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                                    if (e.target.value.length > 5) {
                                        e.target.value = e.target.value.slice(0, 5); // Limit input to 5 digits
                                    }
                                }} />
                            </div>
                            {errors.opportunityAmount && <span style={{ color: 'red' }}>{errors.opportunityAmount}</span>}
                        </div>

                        <div className="field checkBoxOptions">
    <div className="ServicesInterested">
        <div className="field">
            <input type="checkbox" name='servicesInterested' className='custom-checkbox' value='Development' onChange={handleCheckboxChange} />
            <span>Development</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='Design' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>Design</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='Automation' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>Automation</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='SEO' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>SEO</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='PPC' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>PPC</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='Social' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>Social</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='Email' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>Email</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='Reputation' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>Reputation</span>
        </div>
        <div className="field">
            <input type="checkbox" name='servicesInterested' value='Other' className='custom-checkbox' onChange={handleCheckboxChange} />
            <span>Other</span>
        </div>
    </div>
    {errors.servicesInterested && <span style={{ color: 'red' }}>{errors.servicesInterested}</span>}
</div>

                    </div>
                </div>

                <div className='notesBlock'>
                    <h4>Add New Notes</h4>
                    <div className='editor-file-upload'>
                        <CKEditor
                            editor={ClassicEditor}
                            data=""
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                setFormData({ ...formData, notes: editor.getData() });
                            }}
                        />
                        <FileUpload />
                    </div>
                </div>



                <div className="actionBlock">
                    <div className="buttonTags">
                        <h4>Actions</h4>
                        <div className="actionTags">
                            {/* <button className="primaryBtn darkBtn">Log Opportunity Activity </button> */}
                            {/* <button className="primaryBtn darkBtn">Set Follow Up Date </button> */}
                            <span className="global-icon-btn" onClick={(e)=> setShowTeam(true)}>
                                Invite Team Members To Opportunity
                            </span>                           
                             {/* <button className="primaryBtn darkBtn">Convert Opportunity Into Project</button> */}
                        </div>     
                    </div>
                </div>
                <div className="teamMembers">
                     <h4>Team Members</h4>
                    <div className="teamMembersTags">
                        {selectedTeamMembers.map((member) => (
                              <div key={member.id} className='teamdetails'>
                                 <div className='teamMemeberImage'>
                                     <img src="https://t4.ftcdn.net/jpg/02/44/43/69/360_F_244436923_vkMe10KKKiw5bjhZeRDT05moxWcPpdmb.jpg" alt="Team Member"/>
                                 </div>
                                 <div className='teamMemberName'>
                                     <span>{member.label}</span>
                                 </div>
                             </div>
                        ))}
                    </div>
                </div>

                <div className='button-global-submit'>
                <button className="global-icon-btn orange-btn" type="submit" id="save">Save</button>
                            <Link to="/opportunities" className='global-icon-btn'>Cancel</Link>
                </div>
            </form>
            <AddNewCompany show={show} setShow={setShow} onClose={refreshState}  />
            {/* <button onClick={notify}>Notify!</button> */}
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark"/>
                    {/* Popup component */}
                  
      <AddTeamMember
        show={showTeam}
        setShow={setShowTeam}
        onSelectTeamMembers={handleSelectTeamMembers}
      />

      
        </div>

    )
}

export default AddOpportunity
